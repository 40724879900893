import {
  Container,
  makeStyles,
  Paper,
  Grid,
  Typography,
} from "@material-ui/core";
import { green, purple } from "@material-ui/core/colors";
import React from "react";
import clsx from "clsx";
import TitleBlock from "../../../global/components/TitleBlock";
import { LazyLoadImage } from "react-lazy-load-image-component";

const useStyles = makeStyles((theme) => ({
  root: {
  },
  
}));

export default function MiningAndExtractives(props) {
  const classes = useStyles();
  return (
    <Grid container className={clsx(classes.root, "pt-5 mt-5")}>
      <Grid item xs={12} className="pt-5">
        <TitleBlock>Mining and Extractives</TitleBlock>
        <LazyLoadImage
          src={props.image}
          style={{ width: "100%", height: "auto", maxHeight: "300px" }}
        />
      </Grid>
      <Grid item xs={12} className="mt-3">
        <Typography paragraph>
          Uganda rich in Resources, but her citizens continue to be poor. In
          Uganda, efforts to improve agriculture have maintained the nation as a
          peasant economy. Yet Indicators from World Bank and other Partner
          multi laterals still consider share of GDP from Agriculture as an
          indicator for stagnation. Uganda has overtime discovered an abundance
          of mineral resources, energy potential, environment, and all these,
          need harnessing to maximize output.
        </Typography>
        <Typography paragraph>
          The Discovery of oil in particular, led to euphoria, but if not well
          managed and used to develop infrastructure, the oil curse, Dutch
          disease, and her political consequences could make the country less
          stable than it already is.
        </Typography>

        <Typography paragraph>
          Beyond oil are the other aspects of the Extractives sector. Important,
          broadly, are the rights of the small-scale artisanal players, which,
          often, are left on the fringes, with disastrous consequences for
          incomes, lives, and well-being.
        </Typography>
        <Typography paragraph>
          The Initial topical issues around this will include:
          <ul>
            <li>
              Rights and welfare questions around Oil and its exploitation
            </li>
            <li>
              The rights and Integrative efforts by players around Small Scale
              Artesanal miners (in various Mining exploits country wide)
            </li>
            <li>Governance and Regulatory processes</li>
            <li>
              Environmental concerns and consequences arising out of mining and
              oil exploitation
            </li>
          </ul>
        </Typography>
      </Grid>
    </Grid>
  );
}
