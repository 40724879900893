import React, { useState } from "react";

import {
  Button,
  Grid,
  Container,
  makeStyles,
  Typography,
  Dialog,
  Checkbox,
  IconButton,
  Divider,
  Fab,
} from "@material-ui/core";

import { Check, Close, FunctionsTwoTone, Message } from "@material-ui/icons";
import { ArrowForward, Mail, Phone, PinDrop } from "@material-ui/icons";

import { blueGrey } from "@material-ui/core/colors";
import { KijjeInput } from "../../../global/components/inputs/Inputs";
import ApiRequest from "../../../api/ApiRequest";
import { requestSuccess } from "../../../global/store/actions/actions";
import { Alert, AlertTitle } from "@material-ui/lab";
import EmailSentImage from "./email-sent.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const useStyles = makeStyles((theme) => ({
  titleHeading: {

  },
  subtitleHeading: {

  },
  cardRoot: {
    backgroundColor: "#fff",
    padding: theme.spacing(3),
  },
  sideBarDialogRoot: {
    position: "relative",
    top: "0px",
  },
  sideBarDialog: {
    position: "absolute",
    top: "0px",
    height: "100vh",
    background: blueGrey[50],
  },
  divider: {
    backgroundColor: theme.palette.secondary.main,
    width: "40%",
    marginTop: "20px",
    marginBottom: "20px",
    height: "2px",
  },
  dialogRoot: {
    position: "fixed",
    right: "50px",
    zIndex: theme.zIndex.appBar,
    bottom: "50px",
  },
}));

const tealColor = "teal";

export default function SendMessageDialog(props) {
  const classes = useStyles();
  const [dialogState, setDialogState] = useState(false);
  const closeDialog = () => setDialogState(false);
  const openDialog = () => setDialogState(true);
  return (
    <React.Fragment>
      <div className={classes.dialogRoot}>
        <Fab
          onClick={openDialog}
          color="primary"
          className="animated pulse infinite"
        >
          <Message />
        </Fab>
      </div>
      <Dialog open={dialogState} fullWidth fullScreen scroll="body">
        <Grid container style={{ height: "100vh" }} justify="flex-end">
          <Grid item xs={12} sm={8} md={5} lg={4} className={classes.cardRoot}>
            <Grid item xs={12}>
              <IconButton onClick={closeDialog}>
                <Close />
              </IconButton>
            </Grid>
            <ContactUsForm>
              <Button
                variant="outlined"
                color="secondary"
                onClick={closeDialog}
              >
                Close
              </Button>
            </ContactUsForm>
          </Grid>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
}

export function ContactUsForm(props) {
  const classes = useStyles()
  return (
    <ApiRequest
      isForm={true}
      endPoint="https://banking.apis.hapipie.com/admin/install/status" //"https://dev.apis.workmannow.com/apis/contactus/message/send"
      initialPayload={{
        apiKey: "my-api-key",
      }}
      method="post"
    >
      {({ input, submitBtn, data, error, successComplete }) => {
        if (typeof data !== "undefined") {
          if ("mail" in data) {
            if (data.mail == "sent") {
              return (
                <Grid item xs={12} align="center">
                  <LazyLoadImage
                    src={EmailSentImage}
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "contain",
                    }}
                  />
                  <Typography variant="h6" className="my-3">
                    Thank you for contacting us.
                  </Typography>
                  {props.children}
                </Grid>
              );
            }
          }
        }

        return (
          <Grid item xs={12} md={12}>
            {error ? (
              <Alert severity="error" variant="filled">
                <AlertTitle>Error</AlertTitle>
                <Typography>
                  Something went wrong while submitting form! <br /> Please
                  check your network and try again.
                </Typography>
              </Alert>
            ) : (
              ""
            )}
            <Typography variant="h5">
              Contact our team directly
              <Divider className={classes.divider} />
            </Typography>
            <KijjeInput
              label="NAME*"
              labelVariant="small"
              variant="filled"
              labelVariant="small"
              placeholder="John Deo"
              {...input({
                name: "name",
              })}
            />
            <KijjeInput
              label="EMAIL*"
              variant="filled"
              labelVariant="small"
              placeholder="email@example.com"
              {...input({
                name: "email",
                type: "email",
              })}
            />
            <KijjeInput
              label="SUBJECT*"
              variant="filled"
              labelVariant="small"
              placeholder="Why are you contacting us."
              {...input({
                name: "subject",
              })}
            />
            <KijjeInput
              label="MESSAGE*"
              placeholder="Write a message here"
              variant="filled"
              labelVariant="small"
              multiline
              {...input({
                name: "message",
                type: "message",
              })}
            />
            <Button
              endIcon={<ArrowForward />}
              className="mt-4"
              variant="contained"
              color="secondary"
              {...submitBtn({
                type: "submit",
              })}
            >
              Send Message
            </Button>
          </Grid>
        );
      }}
    </ApiRequest>
  );
}
