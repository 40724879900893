import { Typography } from "@material-ui/core";
import React from "react";

const Tags = {
  p: Typography,
};

export default function ContentParser({ type, content }) {
  switch (type) {
    case "p":
      const Tag = Tags[type];
      return <Tag paragraph>{content}</Tag>;
  }
}
