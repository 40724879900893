import React from "react";
import CoreValues from "./CoreValues";
import LandingIntro from "./LandingIntro";
import Mission from "./Mission";
import OurTeam from "./OurTeam";
import ProgrammesList from "./ProgrammesList";
import SectionThree from "./SectionThree";
import SectionTwo from "./SectionTwo";
import Vision from "./Vision";
import Helmet from 'react-helmet'

export default function Landing(props) {
  return (
    <React.Fragment>
      <Helmet>
        <title>Tripartite Initiative for Resource Governance in Africa</title>
        <meta description="The Tripartite project is an effort by concerned Ugandan Citizens to do something about deteriorating Governance in exploitation of strategic public resources, particularly Minerals, Extractives, Biodiversity and Environment, where Uganda, and generally broader Africa would have a comparative advantage to spur positive socio-economic transformation."  />
      </Helmet>
      <LandingIntro />
      <SectionTwo />
      <SectionThree />
      <Vision />
      <Mission />
      <CoreValues />
      <ProgrammesList />
      <OurTeam />
    </React.Fragment>
  );
}
