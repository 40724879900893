import { makeStyles, Grid, Typography, Container } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import { blueGrey, orange } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    // height: "150px",
    backgroundColor: "#f2f2f2",
    backgroundSize: "cover",
    backgroundPosition: "top",
    backgroundImage: "url('/images/landing/about-bg.jpg')",
  },
  infoCard: {
    padding: "20px",
    boxShadow: theme.boxShadows[3],
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "warp",
    backgroundColor: "#fff",
    borderRadius: "5px",
  },
  backdrop: {
    backgroundColor: "rgba(100%, 100%, 100%, 0.3)",
    backdropFilter: "blur(5px)",
  },
}));

export default function CoreValues(props) {
  const classes = useStyles();
  return (
    <Grid
      container
      className={clsx(classes.root)}
      justify="center"
      alignItems="center"
    >
      <Grid item xs={12} md={12} className={clsx(classes.backdrop, "py-5")}>
        <Container align="center" className="py-md-4">
          <Typography variant="h1" color="primary" className="mb-md-4">
            Core Values
          </Typography>
          <Grid container spacing={3}>
            {[
              "Accountability",
              "Representation",
              "Inclusive & transformative strategy (‘Nothing for us without us’)",
            ].map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index} className="py-4">
                <div className={classes.infoCard}>
                  <Typography
                    variant="h6"
                    style={{ color: blueGrey[900], fontWeight: 300 }}
                  >
                    {item}
                  </Typography>
                </div>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
}
