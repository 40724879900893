import { makeStyles, Grid, Typography, Container } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import { blueGrey, orange } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    // height: "150px",
    backgroundColor: blueGrey[50],
    backgroundImage: "url('/images/landing/about-bg.jpg')",
    backgroundAttachment: "fixed",

    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  text: {
    color: theme.palette.primary.main,
    // fontWeight: 300,
  },
  card: {
    borderTop: "solid #222 1px",
  },
  simpleCard: {
    width: "70px",
    height: "90px",
    backgroundColor: orange[600],
    marginRight: "15px",
  },
  infoCard: {
    padding: "20px",
    boxShadow: theme.boxShadows[3],
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "warp",
    backgroundColor: "#fff",
    borderRadius: "5px",
  },
  backdrop: {
    backgroundColor: "rgba(100%, 100%, 100%, 0.3)",
    backdropFilter: "blur(5px)",
  },
}));

export default function SectionThree(props) {
  const classes = useStyles();
  return (
    <Grid
      container
      className={clsx(classes.root)}
      justify="center"
      alignItems="center"
    >
      <Grid item xs={12} className={clsx(classes.backdrop, "pt-4 pt-md-5")}>
        <Container>
          <div className="d-flex align-items-end">
            <div className={classes.simpleCard}></div>
            <Typography variant="h1" className={classes.text}>
              The Institution at a glance
            </Typography>
          </div>
          <Grid container className={clsx(classes.card, "py-5 my-5")}>
            <Grid item xs={12} md={12}>
              <Typography variant="h2">About us</Typography>
              <Typography paragraph className="pt-md-4">
                The Tripartite project is an effort by concerned Ugandans to do something about deteriorating Governance in
                exploitation of strategic public resources, particularly
                Minerals, Extractives, Biodiversity and Environment, where
                Uganda, and generally broader Africa would have a comparative
                advantage to spur positive socio-economic transformation.
              </Typography>
              <Typography paragraph>
                The thrust of the effort is informed by the fact that Governance
                and Development statistics that seemed to grow positively
                between 1987 and 2006 have recently experienced a steady decline
                and threaten to reach unsustainable levels, risking plunging
                multitudes of nationals on the Continent into more poverty.
              </Typography>
              <Typography variant="h4" className="py-4">
                  The Initiative therefore, seeks to embark on interventions
                  that:
              </Typography>
              <Grid container spacing={3}>
                {[
                  `Increase accountability and transparency in strategic Resource use`,
                  `Promote people’s rights in exploitation of strategic resources`,
                  `Involve Citizens in decision making over strategic resource Use`,
                  `Empower grassroots entities (CBOs, CSOs, and interest groups) with information necessary to make them more vibrant in undertaking pro-poor Budget Advocacy. `,
                ].map((item, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    key={index}
                  >
                    <div className={classes.infoCard}>
                      <Typography>{item}</Typography>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
}
