import { makeStyles, Typography, Grid, Container } from "@material-ui/core";
import React from "react";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundSize: "70% cover",
    backgroundSize: "cover",
    backgroundPosition: "right",
    backgroundRepeat: "no-repeat",
    backgroundPositionX: " -10%",
    marginTop: "70px",
    height: "70vh",
    position: "relative",
    transition: "0.5s ease",
    animation: "sliderAnimation",
    animationName: "sliderAnimation",
    animationDuration: "30s",
    animationIterationCount: "infinite",
    [theme.breakpoints.down("lg")]: {
      // backgroundSize: "79% 100%",
      transition: "0.3s ease",
    },
    [theme.breakpoints.down("xs")]: {
      backgroundSize: "70% cover",
      height: "50vh",

      transition: "0.3s ease",
    },
    ["&::after"]: {
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0,0,0,0.4)",
      content: "'' !important",
      position: "absolute",
      top: "0",
      zIndex: "inherit",
    },
  },
  introText: {
    fontSize: "57px",
    lineHeight: "65px",
    color: "#fff", //theme.palette.secondary.dark, // contrastText,
    // fontFamily: "'Oswald', sans-serif",
    fontWeight: 600,
    transition: "0.3s ease",
    [theme.breakpoints.down("md")]: {
      fontSize: "47px",
      lineHeight: "57px",
      transition: "0.3s ease",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "40px",
      lineHeight: "47px",
      transition: "0.3s ease",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "37px",
      lineHeight: "47px",
      transition: "0.3s ease",
    },
  },
  content: {
    zIndex: "999",
  },
}));

export default function LandingIntro(props) {
  const classes = useStyles();
  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      className={clsx(classes.root, "bg-intro-img")}
    >
      <Grid
        item
        xs={11}
        sm={8}
        md={7}
        className={clsx("mt-5", classes.content)}
      >
        <Container className="py-4 " align="center">
          <Typography
            className={clsx(
              classes.introText,
              "animate__animated animate__bounceYYU"
            )}
            variant="h1"
          >
            Tripartite Initiative for Resource Governance in Africa
          </Typography>
        </Container>
      </Grid>
    </Grid>
  );
}
