import { makeStyles, Grid, Typography, Container } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import { blueGrey, orange } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    // height: "150px",
    // backgroundColor: "#000",
    // backgroundImage: "url('/images/landing/vision-mission-bg.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "top",
    // color: "#fff!important"
  },
}));

export default function Mission(props) {
  const classes = useStyles();
  return (
    <Grid
      container
      className={clsx(classes.root, "py-5")}
      // justify="center"
      alignItems="center"
    >
      <Grid item xs={12} className="py-md-5" >
        <Container align="left">
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h1">Mission</Typography>
              <Typography
                variant="h4"
                style={{ fontWeight: "300",letterSpacing: "2px" }}
                className="py-3"
              >
                “We exist to promote positive results in Poverty Eradication.
                This is undertaken through programmes in advocacy, Community
                empowerment, Information sharing, and activities that promote
                peace and social transformation for Development”
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
}
