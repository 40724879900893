import {
  Container,
  Grid,
  makeStyles,
  Typography,
  Paper,
} from "@material-ui/core";
import { green, orange } from "@material-ui/core/colors";
import clsx from "clsx";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ContentParser from "../../../global/components/ContentParser/ContentParser";
import TeamSlider from "./TeamSlider";
// import TeamSlider from "./TeamSlider";

const useStyles = makeStyles((theme) => ({
  root: {
    // height: "150px",
    backgroundColor: "#f2f2f2",
    // backgroundImage: "url('/images/landing/vision-mission-bg.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "top",
    backgroundColor: theme.palette.primary.main,
    // color: "#fff!important"
  },
  profilePic: {
    width: "150px !important",
    height: "150px !important",
    objectFit: "contain",
    objectPosition: "top",
    borderRadius: "100%",
    backgroundColor: "#fff",
    // border: "solid 1px " + theme.palette.primary.main,
    boxShadow: theme.boxShadows[3],
  },
  profileCardHeader: {
    position: "absolute",
    top: "-75px",
  },
  profileCard: {
    backgroundColor: "#fff",
    boxShadow: theme.boxShadows[3],
    padding: "20px",
    position: "relative",
    marginTop: "70px",
    minHeight: "200px",
    borderRadius: "5px",
  },
  simpleCard: {
    width: "50px",
    height: "90px",
    backgroundColor: orange[600],
    marginLeft: "15px",
  },

  profileCard2: {
    width: "100%",
    paddingTop: "50px",
    height: "100%",
    backgroundColor: "",
    boxShadow: theme.boxShadows[3],
    padding: "20px",
    color: "#222",

    ["&:hover"]: {
      boxShadow: "0 10px 30px rgba(8, 112, 184, 0.4)",
    },
  },
  profilePic2: {
    width: "100px!important",
    height: "100px!important",
    objectFit: "cover",
    borderRadius: "100%!important",
    // position: "absolute",
    // top: "-110px",
  },
  teamMembers: {
    width: "100%",
    backgroundColor: theme.palette.secondary.main,
  },
  boardMembers: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default function OurTeam(props) {
  const classes = useStyles();
  return (
    <Grid
      container
      className={clsx(classes.root)}
      // justify="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Container align="left">
              <BoardMembers />
            </Container>
            <TeamMembers />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const directorSlides = [
  {
    image: "/images/landing/team-member-three.jpeg",
    name: "Nicholas Bariyo",
    position: "Chairman",
    bio: [
      {
        type: "p",
        content: `Nicholas Bariyo is an African award winning Journalist, currently covering East, central and Southern Africa for the Wall Street Journal`,
      },
      {
        type: "p",
        content: `He was the 2020 winner of the Society for Publishers in Asia(SOPA)award for Editorial Excellence where he picked 4 (four) top awards for Explanatory reporting, Business reporting, Investigative reporting and Scoop `,
      },
      {
        type: "p",
        content: `He has also received mentions for Business reporting,video reporting , scoop, and was a finalistin the Loeb Awards (USA).`,
      },
      {
        type: "p",
        content: ` Mr. Bariyo has previously written for the UN IRIN News service and The African Confidential `,
      },

      {
        type: "p",
        content: `He brings to the Initiative a wealth of Experience and knowledge in the Resource Sector,  is a passionate advocate for Sustainable resource use, and a fundraiser.`,
      },

      {
        type: "p",
        content: `His strategic thinking as Board Chair  is most appreciated and will push the Initiative towards greater heights. `,
      },
    ],
  },
  {
    image: "/images/landing/moses.jpeg",
    position: "Board Member",
    name: "Moses Tumwebaze",
    bio: [
      {
        type: "p",
        content: `Moses Tumwebaze brings to the Board a wealth of business and corporate
                  experience and Contacts. Having been a banker, a brewer, and a marketer in the FMG Business sector, where he gained a lot of Experience. Today,
                  Moses is into Oil and Lubricants business and FMG Distribution and
                  Value Chains. He is the proprietor and Managing Director of Motmax Ltd
                  and Motfin Ltd. Mr. Tumwebaze holds a Master of Business administration
                  (MBA) of Makerere University .`,
      },
    ],
  },
  {
    image: "/images/landing/Valerian.png",
    name: "Valerian Tuhimbise",
    position: "Board Member",
    bio: [
      {
        type: "p",
        content: `TIRGA is privilege to have His Worship Valerian Tuhimbise as a Board
                  Member. Valerian is a Magistrate of the Courts of Judicature.
                  Previously, he has worked in the Business/Corporate Sector, and as a Civil
                  Servant both in Judiciary and at Makerere University as Senior Human
                  Resource Officer.`,
      },
      {
        type: "p",
        content: `Valerian holds a BA of Makerere University, a Master of Management
                  Science( Uganda Management Institute), a Bachelor of Laws 9 Makerere
                  University) and a Postgraduate Diploma in Law/ Bar course (Law
                  Development Centre)`,
      },
      {
        type: "p",
        content: `He is well-grounded in matters of Procedure, Judicial and Social
                  Justice, all of which are critical to our mission as a CSO Platform.`,
      },
    ],
  },
];

const BoardMembers = (props) => {
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent="center"
      spacing={3}
      className="py-5 my-md-5"
    >
      <Grid item xs={12}>
        <Typography variant="h2" align="center" className="pb-5">
          Governing Board
        </Typography>
      </Grid>
      <TeamSlider slides={directorSlides} />
    </Grid>
  );
};

const teamList = [
  {
    image: "/images/landing/team-member-two.jpg",
    name: "Asiimwe  Godfrey",
    position: "Programme Officer, Resources& livelihoods",
    bio: [
      {
        type: "p",
        content: `
            Holds a M.Sc. in Population & Reproductive Health (Mak) and a BA. in Social Sciences (KYU). He is a Team player who possesses wide knowledge in organizational/Institutional management and also highly experienced in quantitative & qualitative research plus community sensitization. He is similarly exposed and conversant with Uganda’s public health care issues, cultural and environmental aspects. 
        `,
      },
    ],
  },
  {
    image: "/images/landing/innocent-prof-pic.jpg",
    name: "Innocent Nkwasiibwe",
    position: "Chief Executive Officer",
    bio: [
      {
        type: "p",
        content: `
            Innocent Nkwasiibwe holds an Advanced Master of Development
            Evaluation& Management of Institute for Development Policy &
            Management (IOB/Antwerp), A Masters in Public Administration (
            Bergen) and a BA Social sciences of Makerere University . With
            Interests in Institutional Development, Conservation , Aid
            Management , Public policy, Monitoring, Evaluation and Results
            based Frameworks, Mr. Nkwasiibwe is a Consultant of considerable
            repute, having worked in Technical cooperation supporting
            multiple Civil Society entities build their potential.
        `,
      },
      {
        type: "p",
        content: `
          Mr. Nkwasiibwe has also taken various certifications with the
          ILI/ HRSD Danida, BRSSS, Enabel/ Belgian Technical Development
          agency , ESAURP/NORAD, and EAUI/ Ford Foundation (Uongozi). Mr.
          Nkwasiibwe is a passionate advocate and prolific change agent .
        `,
      },
    ],
  },
];

const TeamMembers = (props) => {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={3}
      justifyContent="center"
      className={clsx("py-5 my-md-5", classes.teamMembers)}
    >
      <Grid item xs={12}>
        <Container>
          <Grid item xs={12}>
            <Typography variant="h2" align="center" className="pb-5">
              Our Team
            </Typography>
          </Grid>
          <Grid
            container
            justifyContent="center"
            justify="center"
            className="mt-5"
          >
            <TeamSlider slides={teamList}></TeamSlider>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export function SlideWrapper(props) {
  const classes = useStyles();
  const { image, name, position, bio } = props;
  return (
    <Grid item xs={12} className="pb-md-5" style={{ height: "100%" }}>
      <Paper className={classes.profileCard2}>
        <div className="w-100 d-flex align-items-end justify-content-start pl-5">
          <LazyLoadImage src={image} className={classes.profilePic2} />
          <div className="pl-4" style={{ color: green[500] }}>
            <Typography align="left">{name}</Typography>
            <Typography variant="h6" align="left" className="3">
              {position}
            </Typography>
          </div>
        </div>
        <div className="px-md-5" align="left">
          {bio.map((item, index) => {
            return <ContentParser {...item} index />;
          })}
        </div>
      </Paper>
    </Grid>
  );
}
