import { configureStore, createSlice } from "@reduxjs/toolkit";


const apiRequestStateChange = createSlice({
  name: "apiRequest",
  initialState: {
    CHANGE_STATE: false,
  },
  reducers: {
    updateApiRequestState: (state, { action, payload }) => {
      const { thread, data } = payload;
      state[thread] = data;
    },
  },
});

const dataStore = createSlice({
  name: "dataStore",
  initialState: {
    CHANGE_STATE: false,
  },
  reducers: {
    updateStore: (state, { action, payload }) => {
      const { thread, data } = payload;
      console.log("Thread:: ", thread);
      console.log("Data :: ", data);
      state[thread] = data;
    },
  },
});

export const { updateStore } = dataStore.actions;

/**
 * Api Request state change for monitoring state globerly
 */
export const { updateApiRequestState } = apiRequestStateChange.actions;

const store = configureStore({
  reducer: {
    dataStore: dataStore.reducer,
    apiRequestStateChange: dataStore.reducer,
  },
});

export default store;
