import {
  Container,
  makeStyles,
  Paper,
  Grid,
  Typography,
} from "@material-ui/core";
import { green, purple } from "@material-ui/core/colors";
import React from "react";
import clsx from "clsx";
import TitleBlock from "../../../global/components/TitleBlock";
import { LazyLoadImage } from "react-lazy-load-image-component";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export default function Transboundary(props) {
  const classes = useStyles();
  return (
    <Grid container className={clsx(classes.root, "mt-5 pt-5")}>
      <Grid item xs={12} className="pt-5">
        <TitleBlock>
          Transboundary water resource management & Conservation
        </TitleBlock>
        <LazyLoadImage
          src={props.image}
          style={{ width: "100%", height: "auto", maxHeight: "300px" }}
        />

        <Typography paragraph className="mt-3">
          Historically, water has brought conflict in Communities. Uganda is
          privileged to hold the Source of the River Nile, which rivervalley is
          a source of livelihood to many neighboring States. But this same
          riparian arrangement is subject to many colonial regulatory
          arrangements that have become a source of tensions in many countries
          with a Stake. The Other riparian area of interest is the Kagera basin.
          But this aside, there are many livelihood questions that rotate around
          water as a Strategic resource, matters related to communities and
          fishing rights, over fishing and Security Arrangements, alternative
          livelihoods for fisherfolk, and the like.
        </Typography>
        <Typography paragraph>
          This Programme area will prioritise:
          <ol>
            <li>
              Equal and reasonable voice in the use of riparian shared resources
            </li>
            <li>
              Promotion of peace and dialogue in resolution of tensions that
              might arise
            </li>
            <li>
              Community Empowerment for transformative Policy Advocacy in the
              use of shared riparian resources
            </li>
            <li>
              Shared environmental responsibilities for the upkeep of riparian
              resources for sustainability and future generation use.
            </li>
          </ol>{" "}
        </Typography>
      </Grid>
    </Grid>
  );
}
