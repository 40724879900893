import { makeStyles, Grid, Typography, Container } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import { blueGrey, orange } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    // height: "150px",
    backgroundColor: "#000",
    backgroundImage: "url('/images/landing/vision-mission-bg.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "top",
    color: "#fff!important",
    backgroundAttachment: "fixed",
  },
}));

export default function VisionMission(props) {
  const classes = useStyles();
  return (
    <Grid container className={clsx(classes.root, "py-5")} alignItems="center">
      <Grid item xs={12}>
        <Container align="right">
          <Grid container justify="flex-end">
            <Grid item xs={12} md={8}>
              <Typography variant="h1">Vision</Typography>
              <Typography
                variant="h3"
                style={{ fontWeight: "300" }}
                className="py-3"
              >
                “Putting prudent natural resource use at the Centre of the
                transformative development agenda in Africa”
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
}
