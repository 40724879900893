import React, { lazy } from "react";
import {
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { grey, orange } from "@material-ui/core/colors";
import { ArrowForward } from "@material-ui/icons";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link, Route, Switch } from "react-router-dom";
import HtmlParser from "react-html-parser";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "0px",
    margin: "0px",
    background: grey[100],
  },
  paper: {
    height: "100%",
    boxShadow: theme.boxShadows[3],
  },

  titleWithBar: {
    ["&::before"]: {},
  },
  block: {
    width: "30px",
    height: "50px",
    backgroundColor: orange[600],
    marginRight: "10px",
  },
  header: {
    width: "100%",
  },
  body: {
    padding: "20px",
  },
  img: {
    width: "100%",
    height: "auto",
  },
  projectView: {
    ["& p"]: {
      ...theme.typography.body1,
    },
    ["& li"]: {
      ...theme.typography.body1,
    },
    ["& h1"]: {
      ...theme.typography.h1,
    },

    ["& h2"]: {
      ...theme.typography.h2,
    },

    ["& h3"]: {
      ...theme.typography.h3,
    },
  },
}));

const projects = [
  {
    image: "/images/projects/sand-project-1-f.jpg",
    title:
      "UGANDA :  Mitigating the exploitation of sand and the effects of unregulated Sand Mining  (Sands Project)",
    path: "/projects/sand-project",
    description: `According to Reuters 2021;
                Unregulated sand mining constitutes about 85% of all mining globally 
                In 2017, it is estimated to have accounted for $99.5 billion, and was projected to grow by 2021 to US $ 481 Billion
                From Shanghai to Seattle, Warsaw to Windhoek, Moscow to Maseru, and Cape town to Casablanca, all world cities are mainly built by sand
              `,
    content: `
    <h2>
        UGANDA : Mitigating the exploitation of sand and the effects of unregulated Sand Mining (Sands Project)

    </h2>
    <p>
        <b>
            According to Reuters 2021;
        </b>
    <ul>
        <li>
            Unregulated sand mining constitutes about 85% of all mining globally
        </li>
        <li>
            In 2017, it is estimated to have accounted for $99.5 billion, and was projected to grow by 2021 to US $ 481
            Billion
        </li>
        <li>
            From Shanghai to Seattle, Warsaw to Windhoek, Moscow to Maseru, and Cape town to Casablanca, all world
            cities are mainly built by sand
        </li>
        <li>
            Sand is the Planet’s most mined building material
        </li>
        <li>
            Products made out of sand include sand itself, concrete, Cement, Glass, and tarmac for roads
        </li>
        <li>
            50 billion tonnes of sand are extracted from Lakes, riverbeds, coastlines, and Deltas annually, globally
        </li>
        <li>
            According to the United Nations Environment Programme (UNEP), per person, 6670 kilogrammes (14500 pounds)
            per year, more than an elephant’s weight in sand is utilized
        </li>
        <li>
            On average, according to the UN, global daily sand demand per person is 18 kilogrammes
        </li>
        <li>
            Over a year, estimates at the UN per person put the figure at 6570 kilogrammes
        </li>
        <li>
            Demand for sand worldwide is growing exponentially, but much of the worlds sand mining activities face
            little or no Government or Civil society scrutiny
        </li>
        <li>
            There are scant regulations for protecting the environment or workers’ safety, and a few entities document
            or monitor the trade in sand for its impact
        </li>
        <li>
            The result of the above is that sand is being extracted far more quickly than it can naturally be replaced.
            This causes environmental damage, and in some cases, jeopardises livelihoods
        </li>
        <li>
            The question of sand mining is not just localized. It’s a global phenomenon
        </li>
        <li>

            Majority do not care about the sites. They are spread worldwide, and too many to effectively monitor
            comprehensively. It is therefore, difficult to estimate with accuracy, the magnitude of the challenges
            around sand mining

        </li>
        <li>
            Globally, there is need for a programme to monitor and manage the sand mining Industry as a first step to
            controlling plunder. Standardising would mean that miners, especially the artisanals and those operating on
            a small scale need not be rendered criminals to operate
        </li>
        <li>
            There is need to examine technologies for material re-cycling
        </li>
        <li>
            While there is talk of global sand scarcity, there is need for data. In its absence, the talk does not nmake
            much sense, hence, more research in this area
        </li>
    </ul>



    </p>
    <div>
        <p>
            <b>
                According to Reuters 2021;
            </b>
        </p>
        <img style="width: 100%;  height: auto;" src="/images/projects/sand-progect-1.jpg" alt="">
        <ul>
            <li>
                <small>
                    Figure 1: Mp Alex Byarugaba, Parliaments Natural Resource Committee chairman at one of the sand
                    mines in
                    Lwera, L.Victoria catchment
                </small>

            </li>
                <img style="width: 100%;  height: auto;" src="/images/projects/sand-progect-2.jpg" alt="">
            <li>
                <small>
                    Figure 2: Commercial sand mining and land degradation in Lwera, Uganda
                </small>
            </li>
            <img style="width: 100%;  height: auto;" src="/images/projects/sand-progect-2.jpg" alt="">
                <small>
                    Figure 3: A complicated mix of human activity at L.Victoria shores
                </small>
        </ul>
    </div>

    <section>
        <p>
            Unregulated mining costs Uganda revenue income, environmental damage, and exploitation of vulnerable
            communities . Criminal enterpreneurs have taken advantage, exploiting the unregulated sector , and posing as
            ‘Investors’ have formed local cartyels with connections. Mining and shipments of sand are taking place
            through a regional black market .
        </p>
        <p>
            Sand is valuable, and non renewable as a commodity , and the demand for it by mega building infrastructure
            on the local, continental and global markets has made the stakes higher. The trans boundary nature of this
            trade in sand makes mining in strategic habitats such as rivers, coastlines, as well as sandpits lucrative,
            with grave consequences for conservation of the eco system
        </p>
        <p>
            The mining is labour intensive and employs many youths and children. This raises the question of child
            labour. The methods used are various and include dredging from wetlands, rivers and swamps close to the L.
            Victoria basin mostly in the rural districts of Masaka, Kalungu, Gomba and Mpigi. Mining is also done using
            pits in riverline ecologies
        </p>
        <p>
            Tracing sand movement , various crimes can be identified along the value chain, including while mining, at
            transportation, the Interlocutors involved, and sale at regional and global market .


        </p>
        <p>
            The Immediate market for Uganda are the neighbouring countries of Democratic Republic of Congo(DRC), South
            Sudan, and Rwanda. The sand is taken on heavy lorries, and is a sold product by the time of Loading . Large
            part of the sales goes to China. River sand is the most trafficked , and a lorry ranges between 200,000($50)
            and 800,000($215). Smugglers forge export licences as it is difficult to distinguish between legally and
            illegally mined sand . At border points, there ios obvious Collusion with tax authorities ( ENACT/ Institute
            for security studies)
        </p>
        <p>
            With UNEP forecasting a demand increase of 5.5% per year, this trade can only expand. There is need to break
            the informality, cut the crime and state revenue loss, while increasing chances of sustainable legal mining
            by combating trafficking
        </p>
        <p>
            “The expansive Lwera wetland...is a major water catchment area that connects several rivers and
            wetlands...and drains directly into Lake Victoria. For years on, many of the locals have lived in peace with
            the surroundings. However, over the past couple of months, sharp noises from [sand] dredgers are heard...The
            process of sand mining requires stripping the soil bare, meaning the topography of the area (vegetation,
            animals, soil, and bedrock) are transported to different areas...This process [also] requires more land for
            this purpose and takes large volumes of water...
        </p>
        <p>
            Since the Chinese companies - Hong Hi Jeng Cai, Lwera Swamp, Site light lakes - are capable of importing in
            such powerful dredgers, according to locals and environment experts from the Uganda National Environment
            Management Authority (Nema), they are able to cause more soil stripping. So far, according to...the Nema
            environment monitoring and compliance director, the mining companies in the areas have contravened their
            license obligations...Dredging the sand 200 meters towards the highway puts the busy road, which connects
            Uganda to Tanzania on the brink of soil erosion through constant flooding and degradation. Recently,
            torrential rains caused flooding in the same area displacing more than 500 residences .
        </p>
        <p>
            The destruction of the Lake Victoria is not a local thing though as it affects an inter-state issue as an
            estimated 30 million people depend on the 68,800 square kilometer lake for survival directly but the number
            swells with some countries using it to generate hydro power and irrigation, among other purposes” ( The
            Daily monitor)
        </p>
        <img style="width: 100%; height: auto;" src="/images/projects/sand-progect-4.jpg" />
        <p>
            <small>
                Figure 4: An Excavator mines sand at Buwaya , Wakiso on the shores of L. Victoria
            </small>
        </p>
        <img style="width: 100%; height: auto;" src="/images/projects/sand-progect-5.jpg" />
        <p>
            <small>
                Figure 5: Sandmining eroding beach sand in sierra Leone ( Courtesy of The independent)
            </small>
        </p>



    </section>
    `,
  },
];

const content1 = <div></div>;

export default function Projects(props) {
  const classes = useStyles();
  const module = props.module;
  return (
    <main className={classes.root}>
      <Container className="mt-5 py-5">
        <Grid container spacing={3} xs={12} className="py-5">
          <Grid item xs={12}>
            <div className="d-flex  align-items-center">
              <div className={classes.block}></div>
              <Typography className={classes.titleWithBar} variant="h3">
                Projects
              </Typography>
            </div>
          </Grid>
          <Switch>
            {projects.map(({ content, path }, index) => {
              return (
                <Route
                  path={path}
                  key={index}
                  render={(props) => (
                    <ProjectView>{HtmlParser(content)}</ProjectView>
                  )}
                />
              );
            })}
            <Route path={module.path}>
              <Index />
            </Route>
          </Switch>
        </Grid>
      </Container>
    </main>
  );
}

const Index = (props) => {
  const classes = useStyles();
  return projects.map(({ title, description, image, path }, index) => {
    return (
      <Grid item xs={12} md={4} key={index}>
        <Paper className={classes.paper}>
          <div className={classes.header}>
            <LazyLoadImage src={image}
              className={classes.img}
            />
          </div>
          <div className={classes.body}>
            <Typography variant="h4">{title}</Typography>
            <Typography className="mt-2">
              {description.slice(0, 190)}...
            </Typography>
            <Button
              className="mt-3"
              endIcon={<ArrowForward />}
              color="primary"
              variant="contained"
              component={Link}
              to={path}
            >
              Read More
            </Button>
          </div>
        </Paper>
      </Grid>
    );
  });
};

const ProjectView = (props) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} md={10}>
      <div className={classes.projectView}>{props.children}</div>
    </Grid>
  );
};
