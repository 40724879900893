import { makeUrl as makeUrlOld } from "../hooks";
import Blog from "../pages/Blog";
import Events from "../pages/Events";
import Landing from "../pages/Landing";
import Partners from "../pages/Partners";
import Programmes from "../pages/Programmes";
import EnvironmentAndDiversity from "../pages/Programmes/EnvironmentAndDiversity";
import MiningAndExtractives from "../pages/Programmes/MiningAndExtractives";
import PolicyAndAdvocacy from "../pages/Programmes/PolicyAndAdvocacy";
import Transboundary from "../pages/Programmes/Transboundary";
import Projects from "../pages/Projects";
import ResourceCenter from "../pages/ResourceCenter";

const makeUrl = (title) => "/" + makeUrlOld(title);

export const programmesList = [
  {
    img: "/images/landing/mining.jpg.jpeg",
    path: "/programmes/mining-and-extractives",
    description: `Uganda is rich in Resources, but her citizens continue to be poor. In Uganda, efforts to improve agriculture have maintained the nation as a peasant economy. Yet Indicators from the World Bank and other Partner multi-laterals still consider the share of GDP from Agriculture as an indicator for stagnation. Uganda has over time discovered an abundance of mineral resources, energy potential, environment, and all these, need harnessing to maximize output.`,
    name: "Mining and Extractives Governance",
    path: "/programmes/mining-and-extractive",
    //hash: "/programmes#mining-and-extractives",
    Component: MiningAndExtractives,
  },
  {
    img: "/images/landing/transboundary.jpeg",
    path: "/programmes/transboundary-water-resource-management",
    //hash: "/programmes#transboundary-water-resource-management",
    Component: Transboundary,
    name: "Transboundary water Resource Governance",
    description: `Historically, water has brought conflict in Communities. Uganda is privileged to hold the Source of the River Nile, which rivervalley is a source of livelihood to many neighboring States. But this same riparian arrangement is subject to many colonial regulatory arrangements that have become a source of tensions in many countries with `,
  },
  {
    name: "Environment and Biodiversity Conservation",
    path: "/programmes/environment-and-bio-diversity",
    //hash: "/programmes#environment-and-bio-diversity",
    Component: EnvironmentAndDiversity,
    img: "/images/programes/mining-1.jpg",
    description: `The focus in this programme will be: Working with statutory bodies and like minded agencies to keep the Environmental protection agenda alive on the decision making table.
                Ensure that Budget process prioritizes environmental concerns, both those supporting the 1st 2 programme areas, but also Climate change agenda`,
  },
  {
    img: "/images/landing/policy.jpg",
    name: "Policy and Advocacy",
    path: "/programmes/policy-and-advocacy",
    //hash: "/programmes#policy-and-advocacy",
    Component: PolicyAndAdvocacy,
    description:
      "Civicus (2015) views Advocacy as ‘the strategic and deliberate process to bring about change in policies and practice’. It can happen at local, national, regional and international levels, and an advocacy strategy that seeks to achieve comprehensive change should involve coordinated activity",
  },
];

const appLinks = {
  landing: {
    path: "/",
    name: "Home",
    icon: "",
    Component: Landing,
  },

  modules: [
    {
      path: "/",
      name: "Home",
      icon: "",
      Component: Landing,
      // sub//hashLinks: [
      //   {
      //     name: "Who we are",
      //     path: "/#who-we-are",
      //   }
      // ],
    },
    {
      path: "/programmes",
      name: "Programmes",
      icon: "",
      Component: Programmes,
      subLinks: programmesList,
    },
    {
      path: "/projects",
      name: "Projects",
      icon: "",
      Component: Projects,
    },
    {
      path: "/blog",
      name: "Blog",
      icon: "",
      Component: Blog,
    },
    {
      path: "/events",
      name: "Events",
      icon: "",
      Component: Events,
    },
    {
      path: "/resource-center",
      name: "Resource Center",
      icon: "",
      Component: ResourceCenter,
    },
    // {
    //   path: "/others",
    //   name: "Others",
    //   icon: "",
    //   // Component: Others,
    //   subLinks: [],
    // },
    {
      path: "/partners",
      name: "Partners",
      icon: "",
      Component: Partners,
    },

    // {
    //   path: "/contact-us",
    //   name: "Contact Us",
    //   icon: "",
    //   Component: ContactUs,
    // },
  ],
};

export default appLinks;
