import {
  Container,
  Fab,
  Grid,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { purple, red, teal, yellow } from "@material-ui/core/colors";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  NextWeekOutlined,
} from "@material-ui/icons";
import clsx from "clsx";
import React from "react";
import { Carousel } from "react-responsive-carousel";
import { SlideWrapper } from "./OurTeam";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: "url('/images/programmes/')",
    ["& .carousel .slide"]: {
      backgroundColor: "transparent",
      marginTop: "15px"
    },
  },
  paper: {
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    // boxShadow: theme.boxShadows[3],
    backgroundColor: purple[500],
    color: "#fff",
    ["& *"]: {
      color: "#fff",
    },
  },
  slider: {
    width: "100%",
    backgroundColor: teal[500],
  },
  indicator: {
    width: "10px",
    marginLeft: "20px",
    height: "30px",
    marginTop: "5px",
    float: "left",
    backgroundColor: "#fff",
    borderRadius: "5px",
  },
  nextArrow: {
    position: "absolute",
    top: "2px",
    left: "70px",
    backgroundColor: "#fff",
    borderRadius: "5px",
    padding: "10px",
  },
  prevArrow: {
    backgroundColor: "#fff",
    borderRadius: "5px",
    padding: "10px",
  },
}));

export default function TeamSlider(props) {
  const classes = useStyles();
  const Indicator = (onClickHandler, isSelected, index, label) => {
    return <div align="center" className={classes.indicator}></div>;
  };
  const PrevArrow = (onClickHandler, hasPrev, label) => {
    return (
      <IconButton
        className={classes.prevArrow}
        onClick={(e) => {
          if (hasPrev == true) {
            console.log("Has Prev:: ", hasPrev);
            return onClickHandler(e);
          } else {
            console.log("Does not have prev:: ", hasPrev);
          }
        }}
      >
        <KeyboardArrowLeft />
      </IconButton>
    );
  };
  const NextArrow = (onClickHandler, next, label) => {
    return (
      <IconButton
        className={classes.nextArrow}
        onClick={(e) => {
          if (next == true) {
            return onClickHandler(e);
          }
          return;
        }}
      >
        <KeyboardArrowRight />
      </IconButton>
    );
  };
  return (
    <Container>
      <Grid container className={clsx(classes.root)}>
        <Grid item xs={12} className="">
          <Carousel
            // renderIndicator={Indicator}
            renderArrowPrev={PrevArrow}
            renderArrowNext={NextArrow}
            // centerSlidePercentage={80}
            showThumbs={false}
            showStatus={false}
            autoPlay={true}
            swipeable={true}
            infiniteLoop={true}
          >
            {props.slides.map((slide, index) => (
              <SlideWrapper {...slide} />
            ))}
          </Carousel>
        </Grid>
      </Grid>
    </Container>
  );
}
