/**
 * All api End points have been brought here
 */

import // GET_ALL_PATIENTS
"./apiThreads";

const base_url = "http://127.0.0.1:3333";
const makeUrl = (endPoint, params = {}) => {
  for (var param in params) {
    endPoint = endPoint.replace(param, params[param]);
  }
  return base_url + endPoint;
};

export const makeEndPoint = makeUrl;

export const apiEndPoints = {
  // [GET_ALL_PATIENTS]: {
  //   endPoint: params => makeUrl('/admin/patients/all'),
  //   method: "get"
  // }
};
