import React, { useState } from "react";
import {
  Container,
  Grid,
  makeStyles,
  Link,
  Hidden,
  IconButton,
  Divider,
  Menu,
  MenuItem,
  Button,
  Typography,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import appLinks from "../../../site/routes/appLinks";
import clsx from "clsx";
import Images from "../../../site/images/images";
import SiteDrawer from "./SiteDrawer";
import {
  Facebook,
  LinkedIn,
  MoreVert,
  Twitter,
  YouTube,
} from "@material-ui/icons";
import { grey } from "@material-ui/core/colors";
import { NavHashLink } from "react-router-hash-link";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "20px",
    boxShadow: theme.boxShadows[1],
    position: "fixed",
    backgroundColor: "#fff",
    width: "100%",
    zIndex: theme.zIndex.appBar,
  },

  logoImage: {
    width: "auto",
    height: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "auto",
    },
  },
  navLink: {
    color: theme.palette.secondary.main + "!important",
    ...theme.typography.body1,
    fontWeight: "400!important",
    padding: "10px 5px",
    letterSpacing: 2,
    fontSize: "15px!important",
    flexWrap: "wrap",
    display: "flex",
    flexDirection: "column!important",
    opacity: "0.8",
    transition: "0.3s ease",
    ["& .drop-down"]: {
      position: "absolute",
      opacity: "1 !important",
      display: "none",
      listStyle: "none",
      padding: "0px",
      top: "60px",
      boxShadow: "0 10px 15px 0 rgb(0 0 11 / 30%)",
      padding: "20px",
      transition: "0.3s ease",
      backgroundColor: grey[50],
      // minWidth: "200px",
      ["& li"]: {
        fontWeight: "300 !important",
      },
    },
    ["&:hover .drop-down"]: {
      display: "block",
      transition: "0.3s ease",
    },
  },
  activeLink: {
    color: "#222!important",
    opacity: "1",

    ["&::after"]: {
      width: "50%!important",
      height: "0.5px!important",
      // backgroundColor: "#222",
      border: "0.1px solid " + theme.palette.primary.main,
      content: `' '!important`,
    },
  },
  slogan: {
    fontSize: "1rem",
    textDecoration: "none",
    textAlign: "center",
    color: theme.palette.primary.main,
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export default function SiteAppbar(props) {
  const classes = useStyles();
  return (
    <header className={classes.root}>
      <Container className={clsx("d-flex justify-content-between")}>
        <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <NavLink to="/" className={classes.slogan} align="center">
              <Images.Logo className={classes.logoImage} />
              <Typography
                className={clsx(
                  classes.slogan,
                  " animate__heartBeat animate__animated animate__infinite"
                )}
              >
                Transforming Lives
              </Typography>
            </NavLink>
          </Grid>
          <Grid
            item
            md={9}
            lg={7}
            className="d-flex justify-content-between align-items-center"
          >
            <Hidden smDown implementation="js">
              {appLinks.modules.map(({ name, path, desc, subLinks }, index) => (
                <Link
                  component={NavLink}
                  to={path}
                  key={index}
                  className={classes.navLink}
                  alt={desc}
                  activeClassName={classes.activeLink}
                  exact
                >
                  {name}
                  {typeof subLinks !== "undefined" ? (
                    <ul className="drop-down animated backInDown">
                      {subLinks.map(({ name, path, hash, desc }, index) => {
                        return (
                          <li key={index}>
                            <Link
                              smooth
                              className={classes.navLink}
                              alt={desc}
                              component={NavLink}
                              to={path}
                              activeClassName={classes.activeLink}
                            >
                              {name}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    ""
                  )}
                </Link>
              ))}
            </Hidden>
            <Hidden mdUp implementation="js">
              <SiteDrawer />
            </Hidden>
          </Grid>
          <Hidden smDown implementation="css">
            <Grid item className="d-flex">
              <MoreButton />
              <Hidden mdDown implementation="css">
                <IconButton color="primary">
                  <Facebook />
                </IconButton>
                <IconButton color="primary">
                  <Twitter />
                </IconButton>
                <IconButton color="primary">
                  <YouTube />
                </IconButton>
              </Hidden>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </header>
  );
}

const MoreButton = (props) => {
  const [state, setState] = useState({
    anchorEl: false,
  });

  const handleChange = (e) =>
    setState({ ...state, anchorEl: !state.anchorEl ? e.target : false });
  return (
    <React.Fragment>
      <Button
        onClick={handleChange}
        color="primary"
        component="a"
        target="webmail"
        href="https://tripartiteproject.org/roundcube/"
      >
        Staff Mail
      </Button>
      <Menu anchorEl={state.anchorEl} in={Boolean(state.anchorEl)}>
        <MenuItem>Contact Us</MenuItem>
        <MenuItem>Staff Mail</MenuItem>
      </Menu>
    </React.Fragment>
  );
};
