import {
  Container,
  makeStyles,
  Paper,
  Grid,
  Typography,
} from "@material-ui/core";
import { green, purple } from "@material-ui/core/colors";
import React from "react";
import clsx from "clsx";
import TitleBlock from "../../../global/components/TitleBlock";
import { LazyLoadImage } from "react-lazy-load-image-component";

const useStyles = makeStyles((theme) => ({
  root: {
  },
 
}));

export default function EnvironmentAndDiversity(props) {
  const classes = useStyles();
  return (
    <Grid container className={clsx(classes.root, "pt-5 mt-5")}>
      <Grid item xs={12} className="pt-5">
        <TitleBlock>Environment & Bio diversity</TitleBlock>
        <LazyLoadImage src={props.image} style={{width: "100%", height: "auto", maxHeight: "300px",}} />
        <Typography paragraph className="mt-3">
          The focus in this programme will be:
          <ol>
            <li>
              {" "}
              Working with statutory bodies and like minded agencies to keep the
              Environmental protection agenda alive on the decision making
              table.
            </li>
            <li>
              {" "}
              Ensure that Budget process prioritizes environmental concerns,
              both those supporting the 1st 2 programme areas, but also Climate
              change agenda
            </li>
            <li>
              {" "}
              Work to generally ensure that there is broader Budget transparency
              and that sustainability plans are a critical area in national
              planning
            </li>
            <li>
              {" "}
              Engage in efforts to promote Disaster and Risk Reduction,
              especially where this is likely to be as a result of Activities in
              the 1st 2 Programme areas.
            </li>
          </ol>
        </Typography>
      </Grid>
    </Grid>
  );
}
