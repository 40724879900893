import { makeStyles, Grid, Typography, Container } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
      minHeight: "150px",
      backgroundColor: theme.palette.primary.main,
  },
  text: {
      color: "#fff",
      fontSize: "19px",
      fontWeight: 300,

  }
}));

export default function SectionTwo(props) {
  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.root}
      justify="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Container className="py-3">
          <Typography className={classes.text}>
            “…. We exist to promote positive results in Poverty Eradication.
            This is undertaken through programmes in advocacy, Community
            empowerment, Information sharing, and activities that promote peace
            and social transformation for Development..”
          </Typography>
        </Container>
      </Grid>
    </Grid>
  );
}
