const blogPosts = [
  {
    title: "Beneficial Ownership redefining mineral exploitation in Uganda.",
    author: "Innocent Nkwasiibwe",
    postDate: "6th April, 2021",
    postSummary:
      "Ugandans, even those casually interested in mining and related commercial activities may have occasionally read articles in the media about poor sand mining activities in Kalungu, Masaka, Mpigi ( commonly known as Lwera) and Wakiso districts, mostly close to or on the shores of Lake Victoria. By commission and or omission, responsible government agencies have aided and or abetted actions that over time, may lead to great ecological disaster of our times.",
    postImage: "/images/blog/article-one.jpg",
    postBody: `<style>
  h4 {
      font-family: serif;
      font-weight: 700;
      font-size: 1.8rem;
      text-align: left;
  }

  h5 {
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif font-weight: 700;
      font-size: 1.5rem;
      text-align: left;
      color: #555;
  }

  h4:hover {
      color: green;
  }

  h4::before {
      content: "";
  }

  .p1::first-letter {
      font-size: 2rem;
  }

  .image img {
      border-radius: 100%;
      height: 60px;
      width: 60px;
      border: 1px green solid;
      padding: 2px;
      object-fit: cover;
      object-position: top;
      margin-right: 10px!important;
  }

  .sand img {
      width: 100%;
      height: auto;
  }
  .article-img{
     width: 100%;
      height: auto;
      margin-bottom:20px;
  }
</style>

<div class="w-100" style="text-align: justify">
  <div class="row justify-content-center">
    <div class="col-12 col-md-10 col-lg-9 col-xl-8">
      <div class="my-4">
        <h4>Beneficial Ownership redefining mineral exploitation in Uganda.</h4>
        <h5>What measures are in place to restore the areas mined?</h5>
      </div>
      <div class="image d-flex author align-items-center">
        <div><img class="" src="/images/landing/innocent-prof-pic.jpg" alt="" /></div>
        <div class="">
          <div class="pl-3">Innocent Nkwasiibwe&nbsp;</div>
          <div>6<sup>th</sup> April, 2021</div>
        </div>
      </div>
      <div class="sand mt-4" align="right">
        <img src="/images/blog/article-one.jpg" alt="" />
        <caption>
          <i>image from Btic.com</i>
        </caption>
      </div>
      <article class="m-4">
        <p class="p1">
          Ugandans, even those casually interested in mining and related
          commercial activities may have occasionally read articles in the media
          about poor sand mining activities in Kalungu, Masaka, Mpigi ( commonly
          known as Lwera) and Wakiso districts, mostly close to or on the shores
          of Lake Victoria. By commission and or omission, responsible
          government agencies have aided and or abetted actions that over time,
          may lead to great ecological disaster of our times.
        </p>
        <p>
          The most held narrative is that the biggest environmental destruction
          is caused by dredgers from the swamps and water bodies by ‘Chinese’
          .While this is partly true, it diverts focus to power politics away
          from regulatory failures, greed, and the poor man’s curse of living in
          short term bubbles that, taken together, constitute time bombs for
          posterity.
        </p>
        <p>
          Sand mining has not been recognized as critical, economically .But
          urbanization in the recent decades may in fact have made it central,
          as a significant percentage of brick and mortar is sand based. In
          2019, approximately 24% of Ugandans were living in cities and other
          urban areas. Permanent housing structures in the rural areas were also
          increasing. With an urbanization growth rate of approximately 4.5% per
          annum, sand will be even more critical to goal of eliminating poverty
          by 2030. This could remain elusive for Uganda as the resources are
          exhaustible, and in any case, if sub optimally managed, revenues to
          the state lost, grain sand exported illegally through cartels instead
          of focusing on manufactured products like glass, fiberglass
          insulation, fluorescent lights, and television screens, filtration
          media, Industrial absorbents, metal castings and paints. The latter
          would have a bigger and faster multiplier for the country’s economic
          growth.
        </p>
        <p>
          With Uganda joining the Extractive Industries Transparency Initiative
          (EITI) in 2020, open discussion of extractives, methods, implications,
          revenues and impact on economy should be at the table. Beneficial
          ownership is fast becoming an important tool in untangling the
          question of persistence mining methods that are known to destroy the
          ecosystem, particularly around Lake Victoria that supports livelihoods
          for many in agriculture, fishing, forestry, business, transport, and
          Leisure and hospitality Industries.
        </p>
        <p>
          The gap in information on Licence status of Chinese players, records
          on destination of grain sand, low sand revenues to the State, unclear
          position on sand royalties as stipulated in the Laws should be a cause
          for concern to Ugandans and partners of good will. Are these
          ‘Investors’ infact Chinese? How much does the population know about
          them? Can Ugandans deliver on what foreigners do? , and what would
          that mean for reviewing the challenges of regulating the sector? Are
          technologies like dredgers, wheel loaders, Excavators so advanced that
          Ugandans cannot afford them? And are we adding value through
          exportation of grain sand? What measures are in place to restore the
          areas mined? What is the cost of foreigners crowding out Ugandan
          businessmen? There are no simple answers.
        </p>
        <p>
          Beneficial Ownership perspective compels disclosure of actual
          ownership information of companies in mining and extractives. Through
          regular publication by the state, individuals, who ultimately own or
          have controlling stakes (25% or more in shares) or have significant
          business interests in such entities ( voting rights on company Boards
          for instance) are subjected to scrutiny . Uganda needs to do this in
          Sand mining, and not just in the Gazette, but in leading media too for
          a number of reasons. First, is the dominance of politically exposed
          Persons(PEPs) in mining and related business, invariably affecting the
          effectiveness with which responsible Government departments for
          Instance the Department of Geological Survey and Mines (DGSM)/
          Ministry of Energy and Mineral Development , Inspectorate General of
          Government, Parliament, and National Environment Management Authority
          (NEMA) can exercise their oversight and regulatory roles. A recent
          discussion with players around the western-route town of Lukaya
          quickly highlighted a belief that there is influence peddling , and
          that majority Chinese players are in fact frontmen and companies for
          ‘big people’ in government, plundering the heritage of their children
          away (‘ bilimu abanene’, they said). Further, Civil Society and media
          are only as good as the information they can access , so disclosure in
          this area would lead to greater tools to hold leaders accountable.
          Thirdly, such declarations and public disclosures would level the
          business playing field and provide Ugandans of means who are not
          politically ‘well connected’ an opportunity to invest while at the
          same time meeting their environmental protection obligations.
        </p>
        <p>
          Ugandans must demand that Government lives up to her commitments in
          the EITI, enacts Laws that will mandatorily require Company holdings
          of shares above 25%(Controlling stakes), Voting rights of 25% and
          above and those having a right to appoint or dismiss majorities on
          Boards of Directors to disclose. It is also incumbent on the Ministry
          of Energy and Mineral Development (MEMD) to publish sand exports and,
          together with the tax entity, indicate the revenues and royalties
          applicable and whether or not these are handled per regulations.
        </p>
        <p>
          Travelling on the ‘new’ road between Masaka and Kampala reveals that
          in a short span of time, the road has wasted away largely due to
          overloading, largely by sand truck traffic. This is inspired of weigh
          bridges at vantage points. To circumvent a weigh bridge, you either
          are powerful enough to or someone is not doing their job right .This
          weakens not just infrastructure, but generally, systems for
          accountability and needed revenue mobilization. The poverty we
          continue to experience as a country, especially rural communities
          around areas endowed with resources much demanded like sand result in
          part from informality and secrecy engendered around the exploitation
          of these critical resources.
        </p>
        <p>
          A lot needs to be highlighted round sand as a necessary but often not
          much discussed resource, not just in Uganda, but worldwide. Already,
          Reuters has indicated that unregulated sand mining contributes to 85%
          of all mining globally. While accounting for 99.5 billion US Dollars
          in 2017, it was noted to have risen exponentially to 481 US Dollars by
          2021. With Urbanisation and search for newer innovations in the built
          environment and unending infrastructure demands, it is not exaggerated
          to suggest that sand may be the gold of the coming two decades, and
          should therefore be defended and used to rid Ugandan communities of
          poverty instead of becoming another curse for poverty and inequality
        </p>
      </article>
      <img src="/images/blog/article-two.jpg" alt="" class="article-img"/>
    </div>
  </div>
</div>
`,
  },
  {
    title: "Avoid costly infrastructure diplomacy",
    author: "Innocent Nkwasiibwe",
    postDate: "WEDNESDAY JUNE 02 2021",
    postSummary:
      "At the swearing-in ceremony on May 12,  President Museveni  announced a plan to build a tarmac road connecting Uganda and Burundi through Tanzania .",
    postImage: "/images/blog/article2.jpg",
    postBody: `<style>
  h4 {
      font-family: serif;
      font-weight: 700;
      font-size: 1.8rem;
      text-align: left;
  }

  h5 {
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif font-weight: 700;
      font-size: 1.5rem;
      text-align: left;
      color: #555;
  }

  h4:hover {
      color: green;
  }

  h4::before {
      content: "";
  }

  .p1::first-letter {
      font-size: 2rem;
  }

  .image img {
      border-radius: 100%;
      height: 60px;
      width: 60px;
      border: 1px green solid;
      padding: 2px;
      object-fit: cover;
      object-position: top;
      margin-right: 10px!important;
  }

  .sand img {
      width: 100%;
      height: auto;
  }
  .article-img{
     width: 100%;
      height: auto;
      margin-bottom:20px;
  }
</style>

<div class="w-100" style="text-align: justify">
  <div class="row justify-content-center">
    <div class="col-12 col-md-10 col-lg-9 col-xl-8">
      <div class="my-4">
        <h4>Avoid costly infrastructure diplomacy</h4>
        <h5>What should be the priority of the government with regards to infrastructure in Uganda?</h5>
      </div>
      <div class="image d-flex author align-items-center">
        <div><img class="" src="/images/landing/innocent-prof-pic.jpg" alt="" /></div>
        <div class="">
          <div class="pl-3">Innocent Nkwasiibwe&nbsp;</div>
          <div>2<sup>nd</sup> June, 2021</div>
        </div>
      </div>
      <div class="sand mt-4" align="right">
        <img src="/images/blog/article2.jpg" alt="" />
        <caption>
          <i>image from Btic.com</i>
        </caption>
      </div>
      <article class="m-4">
        <p class="p1">
          At the swearing-in ceremony on May 12,  President Museveni  announced 
          a plan to build a tarmac road connecting Uganda and Burundi through Tanzania . 
          The above announcement came at the heels of another recent one, to the effect 
          that Uganda would build three roads in the Democratic Republic of Congo, neighbour 
          to the west. The Congo projects are supposed to be co-funded.  It will be remembered
           that a few years ago, Uganda, using own resources, built a school in North-West Tanzania. 
           This while some of our very own children here still attended ‘mango tree classes’.
        </p>
        <p>
         With an estimated total road network of  129,469 kilometers, of which 50 per cent
          is access roads, 26 per cent district roads,  20 per cent urban roads and 17 per 
          cent national roads, it is clear that majority of the road network is still  at pre-modern,
           most in a state of disuse. Majority road construction was financed by borrowed resources
            from development partners, bringing into question whether foreign roads are affordable 
            to the Ugandan taxpayer, if at all desirable. That a country failing at own roads goes 
            ahead to spend colossal sums on neighbouring countries’ roads (the business prospects
               highlighted notwithstanding) is not just unprecedented, but in view of complex geopolitics, 
               looks like nugatory grandstanding or adventurism. 
        </p>
        <p>
          As a member of the Northern Corridor transit and Transportation Authority, the leadership should instead be prioritising joint efforts. This multi-modal arrangement links land locked countries in the region to the sea port of Mombasa. The treaty, and its protocols seek to facilitate interstate trade between DR Congo, Burundi, Rwanda, Uganda and Kenya, and has since 2012 been joined by south Sudan. Inter connectivity, here, is not just of roads, but also rail, waterways, pipelines, transportation of dangerous goods and facilitation of transit agencies, traders and or employees. Multi-lateralism seems to work best since each does their bit, and eliminates  free-riding by those not prioritis  ing critical infrastructure at home.  
        </p>
        <p>
          Even here, note must be made that the resources have not necessarily been locally generated. Trade mark East Africa(TMEA), and other bilateral and multi lateral facilities  have pumped  millions of dollars into road construction, and  associated developments like the one stop border posts (EAC/COMESA). To ignore these already existing facilities and channel state resources to unilateral pet projects borders on the irresponsible. Moreover, no evidence has so far been tabled of the other possible counterfactual scenarios. Statements as to possible doubling of cross-border trade, particularly in the Congo are largely unproven and in any case do not make an honest interrogation of the other variables. Geopolitically, it might be better to invest in confidence building. Building roads to bypass problematic neighbours looks like a poorly thought out gamble, yet diverting critical resources from more important transformational aspects of the budget.The 2021/22 Budget framework paper indicates that trade with EAC countries was still at a deficit by about $217million during FY 2019/2020. Certainly, the surpluses in Rwanda and Burundi were affected by non tariff barriers and border closures, but negotiation, while slow, would be the ultimate  guarantee to results .  Geopolitics relies on facts, and one unchangeable reality is that as a country, you don’t choose your neighbours. Some ‘by pass’ roads seem to suggest that we cannot quarrel with currently friendly countries, but a quick look at recent history tells a different story. 
        </p>
        <p>
          In view of priorities in boosting tourism (where cross border resources and opportunities  do, in-fact exist), improving competitiveness (from a cost control perspective),  the need to harness regional markets to take advantage of increased industrial and other output  ( making  stability and harmony critical) , negotiation, not strategic bullying among countries is most logical. Projected efforts in the framework paper to bring back the state in development should not create a monster and actions like creation of state mining companies (with associated monopoly failures) should leave space for business and focus on policy and regulation. 
        </p>
        <p>
         Joint cooperation in strategic mineral exploitation, from a diversity of nationalities in the region might create a sound foundation for discussion (the ICGLR has already created basic ground rules for the value chains). Along with it would come joint infrastructure, either to exploit the resources or as a result of associated activities. Multi-lateral projects, even from a ‘national interest’ perspective have great potential to strengthen prospects for dialogue since mutual benefit for all states would be easily visible. Strategic isolation is costly and complicates relations for neighbours.
        </p>
      </article>
      
    </div>
  </div>
</div>
`,
  },
  {
    title: "Citizen’s concerns should drive current education reform",
    author: "Innocent Nkwasiibwe",
    postDate: "WEDNESDAY JUNE 02 2021",
    postSummary: `As news filtered in last week that a new Eight member Education Review Commission headed
by HE. Nuwe Amanya-Mushega had been set up by the Education and sports minister,
observers of Uganda’s education reform trajectory are debating what this portends.`,
    postImage: "/images/blog/article-three.jpg",
    postBody: `<style>
  h4 {
      font-family: serif;
      font-weight: 700;
      font-size: 1.8rem;
      text-align: left;
  }

  h5 {
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif font-weight: 700;
      font-size: 1.5rem;
      text-align: left;
      color: #555;
  }

  h4:hover {
      color: green;
  }

  h4::before {
      content: "";
  }

  .p1::first-letter {
      font-size: 2rem;
  }

  .image img {
      border-radius: 100%;
      height: 60px;
      width: 60px;
      border: 1px green solid;
      padding: 2px;
      object-fit: cover;
      object-position: top;
      margin-right: 10px!important;
  }

  .sand img {
      width: 100%;
      height: auto;
  }
  .article-img{
     width: 100%;
      height: auto;
      margin-bottom:20px;
  }
</style>

<div class="w-100" style="text-align: justify">
  <div class="row justify-content-center">
    <div class="col-12 col-md-10 col-lg-9 col-xl-8">
      <div class="my-4">
        <h4>Citizen’s concerns should drive current education reform</h4>
        <h5>What to look out for from the current education reform</h5>
      </div>
      <div class="image d-flex author align-items-center">
        <div><img class="" src="/images/landing/innocent-prof-pic.jpg" alt="" /></div>
        <div class="">
          <div class="pl-3">Innocent Nkwasiibwe&nbsp;</div>
          <div>2<sup>nd</sup> June, 2021</div>
        </div>
      </div>
      <div class="sand mt-4" align="right">
        <img src="/images/blog/article-three.jpg" alt="" />
        <caption>
          <i>image from Btic.com</i>
        </caption>
      </div>
      <article class="m-4">
        <p class="p1">
         As news filtered in last week that a new Eight member Education Review Commission headed
by HE. Nuwe Amanya-Mushega had been set up by the Education and sports minister,
observers of Uganda’s education reform trajectory are debating what this portends. The
minster’s Observation that in various respects, the previous foundations for education policy
planning had become outdated seems valid. It is important to get the premises, issues and
methods right this time round, and this is an opportunity that the country should grab for the
desired fundamental shift in policy.
        </p>
        <p>
        Over the years, most education reforms have been driven by politics and not technical
considerations in Primary, secondary, vocational education and, even in the higher education
sub sector. While well intentioned, and occasionally consistent with broader goals of EFA and
SDGS, their success at best can only be rated modest.
        </p>
        <p>Access and classroom indicators may have improved, but questions about the relevance of
various curricula to societal needs, public funding (capitations) and its alignment with real
economy, parent/ stakeholder involvement, standards, enforcement and quality of regulation
and assessment still persist. The big elephant in the room is that decisions are made by
politicians, who, themselves have little or no expertise as educators. Studies like Uwezo have
previously shown rampant schooling ( a good thing), with little or no learning. Over 68% of
children enrolled in public primary schools are likely to drop out before completion and teacher
absenteeism is at 58%. Innovations like the thematic curriculum largely remain underfunded.
Reforms need to go beyond plain proposals to address issues of incentives structures for
teaching and learning in all categories.
        </p>
        <p>
          This review process is an opportunity to thoroughly examine previously ignored angles of the
problem in Uganda’s education sector; the funding challenge, expensive services, neglect of
mother tongue, challenges associated with brain drain, continued mass illiteracy inspite of
improved indicators overtime, wastage, preponderance of generalist education over STEM,
failure to prioritise early Learning and Childhood Development(ECD), the collapse of PTAs, an
unimaginative and poorly funded teacher skilling system , a decline in participatory school
management, unbalanced distribution of opportunity especially to hard to reach areas, and role
duplication among a plethora of Education standards agencies..
        </p>
        <p>
         Uganda’s education planning seems to have heightened inequality. Today, public servants
wouldn’t send their child to a UPE Primary school, expect the Citizen to. Most infrastructural
developments touted do in fact belong to private sector establishments and are hardly
accessible to a common Ugandan citizen. And at Higher education level, the governing law is
lopsided in as far as it seeks to regulate Private players rigorously while leaving Public
Institutions to their own devices. Beyond the skewed law, such regulation is premised on wrong assumptions. The time when government Universities were uncontested bastions of good
practice may have long passed. Guidelines that place regulators and regulated public
Institutions reporting relationship at same level (The minister) need to be reviewed because they
create handicaps of overlaps, low confidence in agencies, conflict of interest, and politicking, in
otherwise supposed technical Institutions and agencies. The Rwendeire Visitation report (2016)
has in part, addressed itself to some of these challenges.
        </p>
        <p>
        Some Departments and Agencies (DAs) have been left with unchecked liberty to review their
regulations. This is much abused. An agency requiring a change in operational Laws should not
unilaterally send proposals to parliament. There is need to bring back the Law Reform
Commission to its rightful place. The Learned Solicitor General’s chambers may have
digressed in mandate from Legal advice, contracting and Civil Litigation to supporting
Departmental Law reform. Weak controls have led to agency Heads hijacking institutions for
personal reasons, rendering them ‘lame-ducks’, incapable of superintending over any
meaningful agenda. The unfettered hand of the Minister in appointing Agency heads has led to
candidates that did not pass selection Interviews routinely appointed to head these same
Departments and Agencies. Cronyism has led to ‘self-selection’ with its negative results.
        </p>
         <p>
            In view of the performance of past reforms, the ministry should engage a broad spectrum of
stakeholders and should not be a closed door event between the Presidency and the Ministry of
Education. I would suggest that the Terms of Reference (ToRs) for this commission should have
been made public through leading media. A structured system for contributions by Citizens and
interested Civil society coalitions should be put in place and matters debated publicly. The
country can no longer afford a situation where a small ‘elite’ monopolises education reform
debate, risking the possibility of ending up with a framework that is neither owned nor
appropriate to rapid changing needs of society.
        </p>
      </article>
        <img src="/images/blog/education.jpg" alt="" class="article-img"/>
    </div>
  </div>
</div>
`,
  },
];

export default blogPosts;
