import React from "react";
import {
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { grey, orange, pink } from "@material-ui/core/colors";
import TitleBlock from "../../../global/components/TitleBlock";
import { Link, Route, Switch, useParams } from "react-router-dom";
import Viewer from "./Viewer";
import { makeUrl } from "../../hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "0px",
    margin: "0px",
    background: grey[100],
  },
  paper: {
    minHeight: "400px",
    height: "100%",
    boxShadow: theme.boxShadows[3],
  },
  titleWithBar: {
    ["&::before"]: {},
  },
  block: {
    width: "30px",
    height: "50px",
    backgroundColor: pink[600],
    marginRight: "10px",
  },
  paperBody: {
    width: "100%",
    padding: "20px",
  },
  postImage: {
    width: "100%",
    height: "200px",
    objectFit: "cover",
  },
}));

export const resourceCenterPosts = [
  {
    description: `The Paris Declaration takes its name from a meeting that took place in Paris in 2005, where over 100 developed and developing
                countries agreed to change the way they do business.
                More than a statement of general principles, the Paris Declaration lays out a practical, action-orientated roadmap to improve the quality
                of aid and its impact on development. It puts in place a series of specific measures for implementation and establishes performance
                indicators that assess progress. It also calls for an international monitoring system to ensure that donors and recipients hold each other
                accountable – a feature that is unique among international agreements.
                The Paris Declaration contains 56 partnership commitments organised around five principles that make aid more effective:`,
    image: "/images/resource-center/paris-post.png",
    type: "pdf",
    url: "/resources/paris-declaration.pdf",
    title:
      "THE PARIS DECLARATION ON AID EFFECTIVENESS: FIVE PRINCIPLES FOR SMART AID",
  },
  {
    description: `This toolkit is for civil society and other stakeholder
                  organisations, coalitions and individuals that wish to influence
                  the post-2015 development agenda, including the design and
                  implementation of the Sustainable Development Goals (SDGs).
                  The toolkit supports advocacy activities at the national,
                  regional and international levels. It does not assume a given
                  level of experience in either the post-2015 development
                  agenda or in advocacy activities. Whether you follow each
                  section and step in turn as a newcomer to the agenda, or only
                  consult those new or relevant to you in order to support a more
                  established programme, we hope it will prove useful to all.`,
    image: "/images/resource-center/advocacy-toolkit.png",
    type: "pdf",
    url: "/resources/civicus-post-2015-advocacy-tool-kit.pdf",
    title: "ADVOCACY TOOLKIT 'INFLUENCING THE POST-2015 DEVELOPMENT AGENDA'",
  },
  {
    description: `
                  Access to basic human needs of health, education, sanitation
   Zero Hunger Providing food and humanitarian relief, establishing 
sustainable food production .....
`,
    image: "/images/resource-center/SDGS.png",
    type: "pdf",
    url: "/resources/SDGs.pdf",
    title: "THE 17 UN SUSTAINABLE DEVELOPMENT GLOBAL GOALS(SDGs)",
  },
  {
    title: "MAP SHOWING CONCESSIONS AND MINERAL OCCURENCIES IN UGANDA",
    type: "pdf",
    url: "/resources/map-showing-consen.pdf",
    image: "/images/resource-center/map-showing-consen.png",
    description: "A map showing concessions and mineral occurencies in Uganda",
  },
  {
    title:
      "AGREEMENT FOR THE ESTABLISHMENT  OF THE ORGANISATION FOR THE MANAGEMENT AND DEVELOPMENT OF KAGERA",
    type: "pdf",
    url: "/resources/Kagera-agreement.pdf",
    image: "/images/resource-center/kagera.png",
    description: `Agreement for the establishment of the Organization for 
the Management and Development of the Kagera 
River Basin (with attached map). Concluded at Rusumo, Rwanda, on 24 August 1977
Authentic texts: French and Englis"`,
  },
];

export default function ResourceCenter(props) {
  const classes = useStyles();
  return (
    <main className={classes.root}>
      <Container className="mt-5 py-5">
        <Grid container spacing={3} xs={12} className="py-5">
          <Grid item xs={12}>
            <TitleBlock>Resource Center</TitleBlock>
          </Grid>
          <Switch>
            <Route
              path="/resource-center/:postIndex/:post"
              render={(props) => <ResourcePost {...props} />}
            />
            <Route path="/resource-center" component={Index} />
          </Switch>
        </Grid>
      </Container>
    </main>
  );
}

function ResourcePost(props) {
  const classes = useStyles();
  const { postIndex } = useParams();
  const post = resourceCenterPosts[postIndex];

  if (post.type == "pdf") {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Viewer url={post.url} />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <h1>Post is not a pdf</h1>
      </Grid>
    </Grid>
  );
}

function Index(props) {
  const classes = useStyles();
  return resourceCenterPosts.map(
    ({ description, image, title, url }, index) => {
      return (
        <Grid item xs={12} md={4}>
          <Paper className={classes.paper}>
            <img src={image} className={classes.postImage} />
            <div className={classes.paperBody}>
              <Typography variant="h4">{title}</Typography>
              <Typography>{description.slice(0, 150)}...</Typography>
              <Button
                variant="contained"
                fullWidth
                component={Link}
                to={"/resource-center/" + index + "/" + makeUrl(title)}
              >
                Continue Reading
              </Button>
            </div>
          </Paper>
        </Grid>
      );
    }
  );
}
