import {
  Container,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { grey, orange } from "@material-ui/core/colors";
import clsx from "clsx";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "0px",
    margin: "0px",
    background: grey[100],
  },
  paper: {
    boxShadow: theme.boxShadows[3],
    padding: "20px",
    height: "100%",
    ["& img"]: {
      width: "100%",
      height: "auto",
    },
  },
  titleWithBar: {
    ["&::before"]: {},
  },
  block: {
    width: "30px",
    height: "50px",
    backgroundColor: orange[600],
    marginRight: "10px",
  },
  divider: {
    width: "100px",
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function Partners(props) {
  const classes = useStyles();
  return (
    <main className={classes.root}>
      <Container className="mt-5 py-5">
        <Grid container spacing={3} xs={12} className="py-5">
          <Grid item xs={12} align="center">
            <div className="d-flex justify-content-center align-items-center py-md-5">
              <Divider className={classes.divider} />
              <Typography
                className={clsx(classes.titleWithBar, "px-3")}
                variant="h2"
              >
                Our Partners
              </Typography>
              <Divider className={classes.divider} />
            </div>
          </Grid>
          {[
            {
              logo: "./images/partners/larker.jpg",
              name: "",
              description: ` `,
            },
            {
              name: "Metropolitan International University",
              logo: "./images/partners/metro.jpeg",
              description:
                "MIU is teaming up with TIRGA through a Memorandum of understanding  ti support the Institutions programming . This MOU builds on synergies of the two Institutions where the Initiatives makes staff and information resources available to students and Staff for Research and Publications in Extractives transparency and sustainable use of transboundary water Resources",
            },
          ].map(({ logo, name, description }, index) => {
            return (
              <Grid item xs={12} md={6} key={index} className="animate delay-3s slideInLeft">
                <Paper align="center" className={clsx(classes.paper, "d-flex align-items-center justify-content-center")}>
                  <div>
                    <div>
                      <LazyLoadImage
                        src={logo}
                        style={
                          index == 1 ? { height: "70px", width: "auto" } : {}
                        }
                      />
                    </div>
                    <Typography variant="h3">{name}</Typography>
                    <Typography
                      style={{ textAlign: "justify", textAlignLast: "left" }}
                    >
                      {description}
                    </Typography>
                  </div>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </main>
  );
}
