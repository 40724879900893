import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import HtmlParser from "react-html-parser";
import { useParams } from "react-router-dom";
import blogPosts from "./blogPosts";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "10px",
  },
}));

export default function BlogPost(props) {
  const classes = useStyles();
  const { postId } = useParams();
  return (
    <Grid container>
      <Grid item xs={12} className={classes.root}>
        {HtmlParser(blogPosts[postId].postBody)}
      </Grid>
    </Grid>
  );
}
