import { createMuiTheme } from "@material-ui/core/styles";
import { blueGrey } from "@material-ui/core/colors";

const theme = createMuiTheme({
  palette: {
    common: {
      black: "#000",
      white: "#fff",
    },
    background: {
      paper: "#fff",
      default: "#fafafa",
    },
    primary: {
      light: "rgba(140, 218, 55, 1)",
      main: "rgba(15, 136, 106, 1)",
      dark: "rgba(62, 116, 6, 1)",
      contrastText: "#fff",
    },
    secondary: {
      light: "rgba(64, 202, 255, 1)",
      main: "rgba(20, 115, 152, 1)",
      dark: "rgba(38, 105, 184, 1)",
      contrastText: "#fff",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    text: {
      primary: "rgba(28, 18, 94, 1)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
    type: "light",
    mainWrapper: {
      grads: {
        innerRoot: {
          backgroundColor: "rgba(255, 255, 255, 0.9)",
        },
        appbarWrapper: {
          // backgroundColor: "rgba(245, 124, 0, 0.8)", // orange
          backgroundColor: "rgba(53, 69, 79, 0.9)", // blueGrey
        },
      },
    },
    grads: {
      transWhite: {
        backgroundColor: "rgba(100%, 100%, 100%, 0.5)", // white background
      },
    },
  },
  boxShadows: [
    `0 1px 2px 0 rgba(0,0,0,0.05)`,
    `0 2px 5px 0 rgba(0,0,0,0.05)`,
    `0 5px 10px 0 rgba(0,0,0,0.05)`,
    `0 10px 15px 0 rgba(0,0,0,0.05)`,
  ],
  bgColor: "#F2F3F5",
  typography: {
    color: "#222",
    fontWeightRegular: "400",
    fontFamily: "poppins",
    lineHeight: "28px",
    fontWeight: "300",
    letterSpacing: "20px",
    body1: {
      fontFamily: "poppins",
      fontSize: "16px",
      lineHeight: "36px",
    },
    h1: {
      // fontFamily: "poppins",
      fontFamily: "poppins, cursive",
      color: "#222",
      fontWeight: 700,
      fontSize: "40px",
    },
    h2: {
      fontFamily: "poppins, cursive",
      fontSize: "30px",
      lineHeight: "42px",
      fontWeight: 700,
    },
    h3: {
      fontSize: "24px",
      fontFamily: "poppins, cursive",
      lineHeight: "34px",
      fontWeight: 700,
    },
    h4: {
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "28px",
      fontFamily: "poppins, cursive",
    },
  },
});

export default theme;
