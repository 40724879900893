import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { grey, orange } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  block: {
    width: "30px",
    height: "50px",
    backgroundColor: orange[600],
    marginRight: "10px",
  },
}));

export default function TitleBlock(props) {
  const classes = useStyles();
  return (
    <div className="d-flex  align-items-center mb-3">
      <div
        className={classes.block}
        style={
          props.backgroundColor
            ? { backgroundColor: props.backgroundColor }
            : {}
        }
      ></div>
      <Typography variant="h3" style={props.style || {}}>
        {props.children}
      </Typography>
    </div>
  );
}

TitleBlock.propTypes = {
  children: PropTypes.string,
};
