import React from "react";
import {
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemIcon,
} from "@material-ui/core";
import clsx from "clsx";
import { FaceOutlined } from "@material-ui/icons";
import ContentParser from "../../../global/components/ContentParser/ContentParser";
import TitleBlock from "../../../global/components/TitleBlock";
import { blueGrey, grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "url('./images/events/calender-events.png')",
    backgroundSize: "50%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
    minHeight: "100vh",
  },
  paper: {
    borderRadius: "20px",
    boxShadow: theme.boxShadows[3],
    transition: "0.3s ease",
    background: "url('./images/events/events-card.png')",
    backgroundSize: "100% 150px",
    backgroundRepeat: "no-repeat",
    ["&:hover"]: {
      transform: "scale(1.05)",
      transition: "0.3s ease",
    },
  },
  root1: {
    height: "100vh",
  },
  header: {
    width: "100%",
    height: "150px",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
  },
  body: {
    borderRadius: "20px",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    backgroundColor: "#fff",
    minHeight: "200px",
  },
  description: {
    color: grey[700] + "!important",
  },
}));

const events = [
  {
    date: "Friday, 26th March, 2021",
    time: "",
    title: "Networking meeting at the Uganda aw Reform Commission (ULRC)",
    location: "Uganda Law Reform Commission (ULRC)",
    category: "Meeting",
    description: [
      {
        p: "Law Reform aspects that would enhance pro poor delivery in Extractives, Transboundary water Resource Governance and Environment and Biodiversity ",
      },
    ],
  },
  {
    title: "14th OECD Forum on Responsible Mineral Supply Chains",
    time: "",
    category: "Meeting",
    location: "",
    date: "27-28 April, 2021",
    image: "/images/event-two.gif",
    description: [
      {
        p: `TIRGA will be participating in the 14th OECD Forum on Responsible Mineral Supply Chains (27th-28th) April 2021
            The 14th Forum on Responsible Mineral Supply Chains will be hosted by the OECD as a virtual forum on 27-28 April 2021 and will bring together representatives from government, business, and civil society.
            As we approach 10 years of implementation of the Due Diligence Guidance for Responsible Mineral Supply Chains, the Forum will address a number of transversal topics, including sessions on global uptake trends, contrasting harmful disengagement from high-risk areas with risk mitigation, and regulatory and policy developments. Other sessions will present new resources developed as part of the OECD implementation programme for the Guidance, including an FAQ on corruption risks, a risk report database, and the cost and value of due diligence. Partner-organised sessions during the Forum week will also explore topics specific to particular regions and minerals.
            `,
      },
    ],
  },
  {
    date: "Tuesday 11th May 2021",
    time: "",
    title: `PARTNERSHIPS MEETING WITH DIGNITY RIGHTS INTERNATIONAL`,
    location: "",
    category: "Meeting",
    description: [
      {
        p: `The CEO, Innocent Nkwasiibwe held a 
                    Virtual Meeting with Executive Director at Dignity Rights International,
                     Professor  of Law at University of Delaware(USA) Erin Daly .
                     It was an opportunity for the two entities to discuss their respective
                     programmes and approaches to Development.
                    Among the things discussed was co-operation,Dignity Rights frameworks 
                    and Programming for Conservation, as well as Advocacy and joint strategies for 
                    reducing Poverty and Deprivation, a key obstacle  to attaining
                     Economic, Social and Cultural Rights and SDGs( 2030 Agenda)
                    The two leaders agreed to explore opportunities for collaboration mutually
                     beneficial to the two Civil Society Organisations`,
      },
    ],
  },
];

export default function Events(props) {
  const classes = useStyles();

  if (events.length < 1) {
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.root1}
        spacing={3}
      >
        <Grid item lg={4} align="center">
          <FaceOutlined
            size="50px"
            style={{ color: "red", fontSize: "50px" }}
          />
          <Typography variant="h2">
            Sorry, we don't have any events at the moment.
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return (
    <Container className="mt-5 pt-5">
      <Grid
        container
        xs={12}
        spacing={3}
        justify="flex-start"
        className={clsx(classes.root, "py-5 my-5")}
      >
        <Grid item xs={12}>
          <TitleBlock>Recent Events Events</TitleBlock>
        </Grid>

        {events.map((event, index) => {
          return (
            <Grid item xs={12} md={4} className="">
              <Paper className={classes.paper}>
                <div className={classes.header}></div>
                <div className={clsx(classes.body, "p-4")}>
                  <List
                    dense
                    subheader={
                      <Typography
                        variant="h4"
                        style={{ color: grey[700] + "!important" }}
                      >
                        {event.title}
                      </Typography>
                    }
                  >
                    <ListItem>
                      <ListItemIcon>
                        <i class="bi bi-geo-alt-fill"></i>
                      </ListItemIcon>
                      <ListItemText>{event.location}</ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <i class="bi bi-calendar-fill"></i>
                      </ListItemIcon>
                      <ListItemText>{event.date}</ListItemText>
                    </ListItem>
                  </List>
                  <div>
                    <Typography>
                      <b>About</b>
                    </Typography>
                    {event.description.map((block, index) => {
                      let tag = Object.keys(block)[0];
                      switch (tag) {
                        case "p":
                          return (
                            <Typography className={classes.description}>
                              {block[tag]}
                            </Typography>
                          );
                      }
                      return "";
                    })}
                  </div>
                </div>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}
