import {
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { grey, purple, teal, blueGrey, orange } from "@material-ui/core/colors";
import { ArrowForward, KeyboardArrowRight } from "@material-ui/icons";
import clsx from "clsx";
import React from "react";
import { Route, Switch, useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import { programmesList } from "../../routes/appLinks";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: "url('/images/programmes/')",
    minHeight: "100vh",
  },
  paper: {
    height: "150px",
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    boxShadow: theme.boxShadows[3],
    backgroundColor: purple[500],
    color: "#fff",
    ["& *"]: {
      color: "#fff",
    },
  },
  slider: {
    width: "100%",
    height: "400px",
    backgroundColor: teal[500],
  },
  indicator: {
    width: "10px",
    marginLeft: "20px",
    height: "30px",
    marginTop: "5px",
    float: "left",
    backgroundColor: "#fff",
    borderRadius: "5px",
  },
  paper: {
    boxShadow: theme.boxShadows[3],
    position: "sticky",
    top: "140px",
  },
  infoCard: {
    boxShadow: theme.boxShadows[3],
    height: "100%",
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    // flexWrap: "warp",
    backgroundColor: "#fff",
    borderRadius: "5px",
  },
  cardHeader: {
    width: "100%!important",
    height: "150px!important",
    backgroundColor: "#f2f2f2",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  cardBody: {
    padding: "20px",
    width: "100%",
  },
  cardname: {
    width: "100%",
  },
  backdrop: {
    backgroundColor: "rgba(0%, 0%, 0%, 0.3)",
    backdropFilter: "blur(5px)",
  },
  simpleCard: {
    width: "70px",
    height: "90px",
    backgroundColor: orange[600],
    marginRight: "15px",
  },
}));

export default function Programmes(props) {
  const classes = useStyles();
  const module = props.module || {};
  const { location } = useHistory();
  return (
    <Container>
      <Grid container spacing={3} className={clsx(classes.root, "mt-md-4")}>
        <Grid item xs={12} md={location.pathname == module.path ? 12 : 8}>
          <Grid
            container
            spacing={3}
            className={location.pathname == module.path ? "mt-5 pt-5" : "not-home"}
          >
            <Switch>
              {module.subLinks.map(({ name, img, hash, path, Component }) => (
                <Route path={path}>
                  <Grid item xs={12} id={hash ? hash.split("#")[1] : path}>
                    <Component image={img} />
                  </Grid>
                </Route>
              ))}
              <Route>
                {programmesList.map(
                  ({ name, img, description, path, hash }, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      key={index}
                      className="py-4"
                    >
                      <div className={classes.infoCard}>
                        <Box
                          className={classes.cardHeader}
                          style={{ backgroundImage: `url('${img}')` }}
                        ></Box>
                        <div className={classes.cardBody}>
                          <div
                            className="d-flex align-items-center"
                            style={{ height: "70px" }}
                          >
                            <Typography
                              variant="h6"
                              style={{ color: blueGrey[900], fontWeight: 700 }}
                              className={classes.cardname}
                            >
                              {name}
                            </Typography>
                          </div>
                          <Typography
                            paragraph
                            style={{ color: grey[500], textAlign: "justify" }}
                          >
                            {description.slice(0, 200)}...
                          </Typography>
                          <Button
                            variant="contained"
                            component={NavLink}
                            to={path}
                            fullWidth
                            endIcon={<ArrowForward />}
                          >
                            Learn More
                          </Button>
                        </div>
                      </div>
                    </Grid>
                  )
                )}
              </Route>
            </Switch>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          className={clsx(
            "pt-5",
            location.pathname == module.path ? "d-none" : ""
          )}
        >
          <Paper className={classes.paper}>
            <List dense>
              {module.subLinks.map(({ name, isHash, hash, path }) => (
                <ListItem component={NavLink} to={path} smooth>
                  <ListItemIcon>
                    <KeyboardArrowRight />
                  </ListItemIcon>
                  <ListItemText>{name}</ListItemText>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
