import {
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import HtmlParser from "react-html-parser";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Switch, Link, Route } from "react-router-dom";
import { makeUrl } from "../../hooks";
import BlogPost from "./BlogPost";
import blogPosts from "./blogPosts";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    marginTop: "70px",
  },
  postRoot: {},
  postImage: {
    width: "100%",
    height: "150px",
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function Blog(props) {
  const classes = useStyles();
  return (
    <Container className="mt-md-5 pt-5">
      <Grid container className={classes.root} spacing={3}>
        <Switch>
          <Route
            path="/blog/:postId/:postTitle"
            render={(props) => <BlogPost {...props} />}
          />

          <Route>
            {blogPosts.map((post, index) => {
              return <BlogPostCard {...post} key={index} id={index} />;
            })}
          </Route>
        </Switch>
      </Grid>
    </Container>
  );
}

function BlogPostCard(props) {
  const classes = useStyles();
  return (
    <Grid item xs={12} md={4}>
      <Paper className={classes.postRoot}>
        <LazyLoadImage src={props.postImage} className={classes.postImage} />
        <div className="p-4">
          <Typography variant="h6" className="mt-4">
            {props.title}
          </Typography>
          <Typography paragraph>{props.postSummary.slice(0, 150)}</Typography>
          <Button
            variant="outlined"
            color="primary"
            component={Link}
            className="mt-3"
            fullWidth
            to={`/blog/` + props.id + "/" + makeUrl(props.title)}
          >
            Read More
          </Button>
        </div>
      </Paper>
    </Grid>
  );
}
