import { current } from "immer";
import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";

export default class Viewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numPages: null,
      pageNumber: 1,
      width: 0,
    };
    this.rootRef = React.createRef(null);
  }
  componentDidMount() {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

    window.addEventListener("resize", () => {
      let clientWidth = 200;

      if (this.rootRef != null) {
        if (this.rootRef.current != null) {
          try {
            clientWidth = this.rootRef.current.clientWidth - 50;
          } catch (error) {
            console.log("Error: ", error);
          }
        }
      }

      this.setState({
        ...this.state,
        width: clientWidth,
      });
    });
    if (this.state.width === 0) {
      if (this.rootRef.current !== null) {
        this.setState({
          ...this.state,
          width: this.rootRef.current.clientWidth - 50,
        });
      }
    }
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ ...this.state, numPages });
  };
  pages = (count) => {
    const list = [];
    for (var i = 1; i <= count; i++) {
      list.push(<Page width={this.state.width} pageNumber={i} />);
    }

    return list.map((page) => page);
  };
  render() {
    return (
      <div className="w-100" ref={this.rootRef} align="center">
        <Document
          onLoadSuccess={this.onDocumentLoadSuccess}
          file={this.props.url}
          onSourceError={(error) => console.log(error)}
        >
          {this.pages(this.state.numPages)}
        </Document>
      </div>
    );
  }
}

// export default function (props) {
//   const [numPages, setNumPages] = useState(null);
//   const [pageNumber, setPageNumber] = useState(1);

//   function onDocumentLoadSuccess({ numPages }) {
//     setNumPages(numPages);
//   }
//   alert("http://localhost:3000" +props.url);

// }
// ;
