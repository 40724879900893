/**Material Ui Components */
import { ThemeProvider } from "@material-ui/core/styles";
import "animate.css";
import "bootstrap-icons/font/bootstrap-icons.css";
// import { Paper, Container, makeStyles } from "@material-ui/core";
/**Style sheets */
import "bootstrap/dist/css/bootstrap-grid.css";
import React, { useState } from "react";
import { Provider as ReduxProvider } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PageNotFound from "../global/components/error_pages/PageNotFound";
import ScrollToTop from "../global/components/navigation/ScrollToTop";
import SiteAppbar from "../global/components/navigation/SiteAppbar";
import SiteFooter from "../global/components/navigation/SiteFooter";
import MainWrapper from "../global/components/warppers/MainWrapper";
// import { grey } from "@material-ui/core/colors";
// import Login from "./pages/Auth/Login";
// import Register from "./pages/Auth/Register";
// import NetworkError from "./components/error_pages/NetworkError";
// import ProtectedRoute from "./components/Auth/ProtectedRoute";
// import { useEffect } from "react";
// import { useState } from "react";
// import InitializingAppLoader from "./components/initializer/InitializingAppLoader";
// import AuthRoute from "./components/Auth/AuthRoute";
import store from "./../store";
// /**Font faces */
// import "typeface-roboto";
import "../scss/app.scss";
import SendMessageDialog from "./pages/ContactUs/SendMessageDialog";
import Landing from "./pages/Landing";
import appLinks from "./routes/appLinks";
/** import theme */
import theme from "./theme/app-theme";

function SiteRouting(props) {
  const [state, setState] = useState({
    loading: true,
  });

  /**
   * isInitializing has three states,
   * 0 when compoenent has just mounted
   * 1 check  authStatus is loading
   * 2 check last activity is loading
   * 3 all is completed and application is ready for use
   *
   */
  // const [isInitializing, setInitializationState] = useState(0);

  // const checkAuth = (login) => (user) => {
  //   setState({ ...state, loading: true });
  // };

  // if (state.loading) {
  //   return (
  //     <Provider store={store2}>
  //       <InitializingAppLoader
  //         afterLoad={() => setState({ ...state, loading: false })}
  //       />
  //     </Provider>
  //   );

  return (
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <ScrollToTop>
            <MainWrapper appbar={<SiteAppbar />}>
              <Switch>
                {appLinks.modules.map((Module, index) => {
                  if (index == 0) {
                    return "";
                  }
                  return (
                    <Route
                      path={Module.path}
                      component={(props) => (
                        <Module.Component module={Module} />
                      )}
                    />
                  );
                })}

                <Route
                  path={appLinks.landing.path}
                  render={(props) => <Landing />}
                />
                <Route>
                  <PageNotFound />
                </Route>
              </Switch>
            </MainWrapper>
            <SiteFooter />
          </ScrollToTop>
        </Router>
      </ThemeProvider>
    </ReduxProvider>
  );
}
export default SiteRouting;
