import {
  Box,
  Button, Container, Grid, makeStyles,

  Typography
} from "@material-ui/core";
import { blueGrey, grey, orange } from "@material-ui/core/colors";
import { ArrowForward } from "@material-ui/icons";
import clsx from "clsx";
import React from "react";
import { NavLink } from "react-router-dom";
import { programmesList } from "../../routes/appLinks";

const useStyles = makeStyles((theme) => ({
  root: {
    // height: "150px",
    backgroundColor: "#f2f2f2",
    backgroundImage: "url('/images/landing/vision-mission-bg.jpg')",
    backgroundAttachment: "fixed",

    backgroundSize: "cover",
    backgroundPosition: "top",
    // color: "#fff!important"
  },
  infoCard: {
    boxShadow: theme.boxShadows[3],
    height: "100%",
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    // flexWrap: "warp",
    backgroundColor: "#fff",
    borderRadius: "5px",
  },
  cardHeader: {
    width: "100%!important",
    height: "150px!important",
    backgroundColor: "#f2f2f2",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  cardBody: {
    padding: "20px",
    width: "100%",
  },
  cardname: {
    width: "100%",
  },
  backdrop: {
    backgroundColor: "rgba(0%, 0%, 0%, 0.3)",
    backdropFilter: "blur(5px)",
  },
  simpleCard: {
    width: "70px",
    height: "90px",
    backgroundColor: orange[600],
    marginRight: "15px",
  },
}));

export default function ProgrammesList(props) {
  const classes = useStyles();
  return (
    <Grid
      container
      className={clsx(classes.root)}
      justify="center"
      alignItems="center"
    >
      <Grid item xs={12} md={12} className={clsx(classes.backdrop, "py-5")}>
        <Container className="py-md-5">
          <div className="d-flex align-items-end">
            <div className={classes.simpleCard}></div>
            <Typography
              variant="h1"
              className="mb-md-4"
              style={{ color: "#fff" }}
            >
              Our Programmes
            </Typography>
          </div>
          <Grid container spacing={3}>
            {programmesList.map(
              ({ name, img, description, path, hash }, index) => (
                <Grid item xs={12} sm={6} md={4} key={index} className="py-4">
                  <div className={classes.infoCard}>
                    <Box
                      className={classes.cardHeader}
                      style={{ backgroundImage: `url('${img}')` }}
                    ></Box>
                    <div className={classes.cardBody}>
                      <div
                        className="d-flex align-items-center"
                        style={{ height: "70px" }}
                      >
                        <Typography
                          variant="h6"
                          style={{ color: blueGrey[900], fontWeight: 700 }}
                          className={classes.cardname}
                        >
                          {name}
                        </Typography>
                      </div>
                      <Typography
                        paragraph
                        style={{ color: grey[500], textAlign: "justify" }}
                      >
                        {description.slice(0, 200)}...
                      </Typography>
                      <Button
                        variant="contained"
                        component={NavLink}
                        to={path}
                        fullWidth
                        endIcon={<ArrowForward />}
                      >
                        Learn More
                      </Button>
                    </div>
                  </div>
                </Grid>
              )
            )}
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
}
