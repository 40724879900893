import React from "react";
import { makeStyles, Grid, Container } from "@material-ui/core";
import { Route, Switch, useLocation } from "react-router-dom";
import { mappedLinks } from "../../maps";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "100vh",
    padding: "0px",
    margin: "0px",
    // backgroundColor: grey[200],
  },
  innerRoot: {
    width: "100%",
    padding: "0px",
    margin: "0px",
  },
  appbarWrapper: {
    width: "100%",
  },


  
}));


export default function MainWrapper(props) {
  const classes = useStyles();
  const { pathname } = useLocation();
  return (
    <React.Fragment>
      {props.appbar}
      <div style={{width: "100%"}}>
        <Grid container justify="center">
          <Grid item xs={12} sm={12}>
            {props.children}
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}
