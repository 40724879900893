import {
  Container,
  makeStyles,
  Paper,
  Grid,
  Typography,
} from "@material-ui/core";
import { green, orange, purple, teal } from "@material-ui/core/colors";
import React from "react";
import clsx from "clsx";
import TitleBlock from "../../../global/components/TitleBlock";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
  },
  paper: {
    height: "150px",
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    boxShadow: theme.boxShadows[3],
    backgroundColor: purple[500],
    color: "#fff",
    ["& *"]: {
      color: "#fff",
    },
  },
  box: {
    padding: "10px",
    backgroundColor: teal[50],
    borderRadius: "15px",
  },
}));

export default function PolicyAndAdvocacy(props) {
  const classes = useStyles();
  return (
    <Grid container className={clsx(classes.root, "pt-5 mt-5")}>
      <Grid item xs={12} className="pt-5">
        <TitleBlock>Policy Analysis, Research & Advocacy (PARA)</TitleBlock>
        <Typography paragraph>
          Civicus (2015) views Advocacy as{" "}
          <span style={{ color: orange[700] }}>
            ‘the strategic and deliberate process to bring about change in
            policies and practice’.
          </span>{" "}
          It can happen at local, national, regional and international levels,
          and an advocacy strategy that seeks to achieve comprehensive change
          should involve coordinated activity at all levels. Advocacy can go
          beyond aiming simply to change policies, to challenge and change how
          people perceive their ability to influence decision-making processes.
        </Typography>
        <Typography paragraph>
          This programme is designed to ensure that there is timely and accurate
          information on which recommendations and Advocacy strategies can be
          formulated.
        </Typography>
        <Typography paragraph className={classes.box}>
          <b>At the IRGA;</b>
          <ul>
            <li> Is result oriented, and for this to happen;</li>
            <li>
              Is premised on plans and desired results, taking into account all
              the circumstances crucial for successful stakeholder engagement
              (rights holders and duty bearers)
            </li>
            <li>
              Is based on a clear, actionable Advocacy strategy that
              encapsulates broader areas of focus (largely to avoid overstretch
              and uncoordinated effort)
            </li>
            <li> Is evidence based</li>
            <li>
              Advocacy strategies formulated based on multi layered / level
              nature of actors (Local, National, Regional, Global)
            </li>
          </ul>
        </Typography>
        <Typography paragraph className={classes.box}>
          <b> International & regional Sources: </b>
          <ul>
            <li>
              International & regional Sources: (UN United Nations and
              associated agencies), African development Bank (ADB) The World
              Bank& IMF, OECD, AU (African Union& agencies ), ASEAN, BRICS
              secretariat, COMESA, SADC( and agencies), EAC Secretariat, IGAD,
              Nile basin Initiative, International Conference for the Great
              Lakes Region(IGLR), Economic community for west African states (
              ECOWAS), World wide fund for nature(WWF), International Union for
              the conservation of Nature (IUCN) , African wild life foundation
              (AWF) Care International( CARE)
            </li>
            <li>
              Governance Data Sources: ( Freedom House, World Bank,
              Afrobarometer, African Leadership Institute (AFLI), Polity IV
            </li>
            <li>
              National sources: (Uganda Bureau of Statistics (UBOS), National
              Population council(NPC), Directorate of geological Survey and
              Mines (DGSM), Ministry of water and Environment (Applicable
              directorates), National Environmental Management authority (NEMA),
              Uganda Wild life authority( UWA), Specialised Protection Agencies
              (Uganda Police), National NGO forum ( NGO FORUM), African
              Insititute for Energy Governance( AFIEGO), African Centre for
              Energy & mineral Policy ( ACEMP) Ecological Christian
              Organisation(ECO), the media
            </li>
            <li>
              Local Sources ( Local district offices), CBO and local CSO actors
              , Citizens in mining and living along trans boundary water
              resources, Local media Crucial Information Sources
            </li>
          </ul>
        </Typography>
        <Typography paragraph className={classes.box}>
          <b>Policy/ Change targets</b>
          <ul>
            <li>
              Government ( Ministries, Departments and agencies) with work
              relevant or incidental to the programmes focus)
            </li>
            <li>
              Ministry of Finance Planning& Economic Development ( Because of
              its central planning and funding role)
            </li>
            <li> Parliament(s) Nationally and Locally</li>
            <li> Law reform agencies</li>
            <li>
              Funders in Critical areas for instance JLOS Funding mechanism for
              Governance or CDC and PEPFAR in Health or Environmental and Water
              / Climate sector Wide approaches 9SWAPS)
            </li>
          </ul>
        </Typography>
        <Typography paragraph className={classes.box}>
          <ul>
            Partnering for advocacy
            <li>
              The Initiative Opts to partner with other civil society and
              academia to realize policy advocacy objectives
            </li>
            <li> This will be through joint research and Action</li>
            <li>
              Such an approach maximizes on resources for Advocacy while
              integrating fresh insights
            </li>
            <li>
              Partnerships for Dialogue will drive advocacy at National,
              Regional and other levels
            </li>
          </ul>
        </Typography>
      </Grid>
    </Grid>
  );
}
