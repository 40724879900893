import React, { useEffect } from "react";
import Axios from "axios";
import { useState } from "react";
import { apiEndPoints } from "./apiEndPoints";
import {
  Button,
  Button as ButtonComponent,
  CircularProgress,
} from "@material-ui/core";
import propTypes from "prop-types";
import { useSelector, useDispatch, useStore } from "react-redux";
import store, { updateStore } from "../store";
import clsx from "clsx";
import { v4 as uuid } from "uuid";

let renderCount = 1;

export default class ApiRequest extends React.PureComponent {
  constructor(props) {
    super(props);
    /**
     * Iniialize state state will only have the dynamic data of the api request
     */
    this.state = {
      validationErrorMessage: {},
      validationErrors: {},
      errorMessage: "",
      message: "",
      payload: this.props.initialPayload || {},
      data: this.props.initialData,
      loading: false,
      loadingState: 0,
      refreshTime: 500,
      error: false,
      refreshing: false,
      formData: new FormData(),
      fileInputs: {},
    };

    this.button = props.button || false;
    this.callbacks = {
      success: () => {},
      error: () => {},
      validationError: () => {},
      ...this.props.callbacks,
    };

    /**
     * [this.props.addOnThread]
     * This is used to enable multiple requests
     * Example we might be fetching data using the same thread but different ids.
     * An addon thread can be that id
     */
    if (typeof this.props.addOnThread === "undefined") {
      this.addOnThread = "";
    } else {
      this.addOnThread = this.props.addOnThread;
    }
    this.thread = this.props.thread + this.addOnThread;
  }

  /**
   * Input for input fields
   */
  input = (options) => {
    const { name, defaultValue } = options;
    // setPayload({
    //   ...payload,
    //   [name]: options.value || ""
    // })
    const showHelperText = (name) => {
      if (name in this.state.validationErrors) {
        return {
          helperText: this.state.validationErrors[name],
          error: true,
        };
      } else {
        return {
          helperText: options.helperText || "", //JSON.stringify(validationErrors);
        };
      }
    };
    return {
      ...options,
      onChange: (e) => {
        const { name, value } = e.target;

        this.setState({
          ...this.state,
          payload: {
            ...this.state.payload,
            [name]: value,
          },
        });
      },
      value: this.state.payload[name] || defaultValue,
      ...showHelperText(name),
    };
  };

  /**
   * Set Payload
   */
  setPayload = (payload) => {
    this.setState({
      ...this.state,
      payload: {
        ...this.state.payload,
        ...payload,
      },
    });
  };

  apiConfig = () => {
    var method = this.props.method;
    var url = this.props.endPoint;
    if (typeof this.props.thread !== "undefined") {
      if (this.props.thread in apiEndPoints) {
        var { endPoint, method } = apiEndPoints[this.props.thread];
        if (typeof endPoint === "function") {
          var url = endPoint(this.props.params);
        } else {
          var url = endPoint;
        }
      }
    } else {
      // alert("apiEndPoint: is undeined");
    }

    var data = this.state.payload;
    var contentType = "application/json";

    if (
      this.props.withFileUpload === true ||
      this.props.withFileUpload === "true"
    ) {
      contentType = `multipart/form-data; boundary=${Math.random()
        .toString()
        .substr(2)}`;
      var formData = new FormData();
      for (var key in this.state.payload) {
        formData.append(key, this.state.payload[key]);
      }
      data = formData;
    }

    return {
      url,
      method,
      contentType: contentType,
      data: data,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "*",
      },
      widthCredentials: true,
    };
  };

  componentDidUpdate() {
    console.log("Render:: ", renderCount++);
    console.log(
      "OldThead:: ",
      this.thread,
      "\nNewThread:: ",
      this.props.addOnThread
    );
    if (typeof this.props.addOnThread === "undefined") {
      this.addOnThread = "";
    } else {
      this.addOnThread = this.props.addOnThread;
    }

    const oldThread = this.thread;
    this.thread = this.props.thread + this.addOnThread;
    const dataStore = store.getState().dataStore;

    /**
     * If there was un update on the thread and its not the same as the old one
     * WE shall need to request that data from the server for
     */
    if (oldThread !== this.thread) {
      if (typeof this.thread !== undefined) {
        if (this.thread in dataStore) {
          this.setState({
            ...this.state,
            data: dataStore[this.thread],
          });
        } else {
          if (this.props.autoload === true || this.props.autoload === "true") {
            this.request();
          }
        }
      }
    }
  }

  componentDidMount() {
    const requestChange = store.getState().apiRequestStateChange;
    const dataStore = store.getState().dataStore;

    if (typeof this.thread !== undefined) {
      if (this.thread in dataStore) {
        this.setState({
          ...this.state,
          data: dataStore[this.thread],
        });
      } else {
        if (this.props.autoload === true || this.props.autoload === "true") {
          this.request();
        }
      }
    }

    //Subscribe for future changes.
    const unsubscribe = store.subscribe(() => {
      const dataStore = store.getState().dataStore;

      if (this.thread in dataStore) {
        if (
          JSON.stringify(dataStore[this.thread]) !==
          JSON.stringify(this.state.data)
        ) {
          this.setState({
            ...this.state,
            ...dataStore[this.thread],
          });
        }
        // unsubscribe();
        return;
      }
    });
  }

  /**
   * Handle the file input change
   */
  onFileInputChange = (e) => {
    var file = e.target.files[0];
    const { name } = e.target;
    let maxFileSize = e.target.getAttribute("maxFileSize") || 4;
    if (typeof file === "undefined") {
      return false;
    }

    if (typeof file.type === "undefined") {
      return false;
    }
    var size = (file.size * 10 ** -6).toFixed(0);

    if (Number(size) > Number(maxFileSize)) {
      alert(
        `File Size is greater then ${maxFileSize}mb \n Your file is: ${size}`
      );
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(file);
    this.setState({
      ...this.state,
      fileInputs: {
        ...this.state.fileInputs,
        [name]: {
          rendering: true,
          rendered: false,
        },
      },
    });
    reader.onloadend = () => {
      this.setState({
        ...this.state,
        payload: {
          ...this.state.payload,
          [name]: file,
        },
        fileInputs: {
          ...this.state.fileInputs,
          [name]: {
            rendering: false,
            rendered: true,
            url: reader.result,
            fileName: file.name,
          },
        },
      });
    };
  };

  /**
   * File input
   * This is used when there is need to upload files to the server using the same form
   */
  fileInput = (props) => {
    if (typeof props.children === "function") {
    }
    const inputId = uuid();

    const Label = (props) => (
      <label htmlFor={inputId} className={props.className}>
        {props.children}
      </label>
    );

    return (
      <React.Fragment>
        <input
          type="file"
          id={inputId}
          accept={props.accept}
          onChange={this.onFileInputChange}
          name={props.name}
          maxFileSize={props.maxFileSize}
          className={clsx(props.inputClassName)}
        />
        {typeof props.children === "function"
          ? props.children({
              ...this.state.fileInputs[props.name],
              ...{ Label: Label },
            })
          : props.children}
      </React.Fragment>
    );
  };

  /**
   * Response handler for all requests
   */
  responseHandler = (res) => {
    var state = {
      message: "",
      loading: false,
      refreshing: false,
      data: {},
      errorMessage: "",
      error: false,
      validationErrors: {},
    };

    if (typeof res.data.errorCode !== "undefined") {
      this.callbacks.error(res.data);
      state = {
        ...state,
        errorMessage: "Error",
        data: this.props.initialData,
      };
    }

    if ("success" in res.data) {
      state = { ...state, message: res.data.message };

      // Request was not successful
      if (res.data.success === false) {
        // Check if error exists in the payload
        if ("error" in res.data) {
          // There in an error with the request. this error is known at the server
          if (res.data.error === true) {
            this.callbacks.error(res.data);
            state = {
              ...state,
              errorMessage: res.data.data.message,
              data: res.data,
            };
          }
          // Check if there is a validation error
          if (res.data.validationError == true) {
            this.callbacks.validationError(res.data.data);
            state = {
              ...state,
              errorMessage: res.data.message,
              validationErrors: res.data.data,
            };
          } else {
            state = {
              ...state,
              error: true,
            };
          }
        }
      } else {
        this.callbacks.success(res.data);
        state = {
          ...state,
          data: res.data,
        };
        // const
      }
    } else {
      state = {
        ...state,
        error: true,
      };

      // callbacks(res.data);
    }

    if (typeof this.thread !== "undefined") {
      store.dispatch(
        updateStore({
          thread: this.thread,
          data: res.data,
        })
      );
    }

    this.setState({
      ...this.state,
      ...state,
    });
  };

  /**
   * Handle errors that happen when we are making requests
   */
  errorHandler = (error) => {
    console.log("Error is here:: ", error);

    this.setState({
      ...this.state,
      ["error"]: true,
      loading: false,
    });
    // callbacks.error(error.data);
  };

  /**
   * Make a request to the server
   */
  request = () => {
    this.setState({ ...this.state, loading: true });
    Axios(this.apiConfig()).then(this.responseHandler).catch(this.errorHandler);
  };

  /**
   * Refresh the request
   */
  refreshRequest = () => {
    this.setState({ ...this.state, refreshing: true });
    Axios(this.apiConfig()).then(this.responseHandler).catch(this.errorHandler);
  };

  /**
   *
   */

  /**
   * Button used when submitting data to the server
   */
  submitButton = (props) => {
    if (this.state.loading === true) {
      return (
        <Button
          {...props}
          endIcon={<CircularProgress size="16px" />}
          disabled
        />
      );
    }
    return (
      <Button
        {...props}
        onClick={() => {
          if (typeof props.onClick === "function") {
            props.onClick();
          }
          this.request();
        }}
      />
    );
  };

  render() {
    if (typeof this.props.children === "function") {
      return (
        <React.Fragment>
          {this.props.children({
            payload: this.state.payload,
            loading: this.state.loading,
            refreshing: this.state.refreshing,
            refresh: this.refreshRequest,
            data:
              typeof this.state.data == "undefined"
                ? this.props.initialData
                : this.state.data,
            error: this.state.error,
            setPayload: this.setPayload,
            autoloadStarted: this.state.autoloadStarted,
            errorMessage: this.state.errorMessage,
            input: this.input,
            Button: this.submitButton,
            SubmitButton: this.submitButton,
            FileInput: this.fileInput,
          })}
        </React.Fragment>
      );
    } else {
      return <div></div>;
    }
  }
}

ApiRequest.propTypes = {
  dataIndex: propTypes.any, // the position of data in an array the array default is boolean
  dataPayload: propTypes.any, // This is used when in an array or an object
  withFileUpload: propTypes.bool,
  initialData: propTypes.object,
  thread: propTypes.string,
  initialPayload: propTypes.object,
  endPoint: propTypes.string,
  autoload: propTypes.bool,
  withFileUpload: propTypes.bool,
};
