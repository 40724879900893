import React from "react";
import { Grid, Container, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { Email, LocationCity, Phone } from "@material-ui/icons";
import { programmesList } from "../../../site/routes/appLinks";
import { HashLink } from "react-router-hash-link";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "300px",
    // backgroundColor: "rgba(100%,100%,100%,0.9)",
    // backgroundImage: "url('/images/footer.png')",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  blur: {
    backgroundColor: theme.palette.primary.main,
    // backdropFilter: "blur(20px)",
  },
  copywrite: {
    backgroundColor: theme.palette.primary.dark,
    color: "#fff!important",
    ["&*"]: {
      color: theme.palette.secondary.contrastText,
    },
  },
  footerText: {
    fontSize: "13px",
    color: theme.palette.secondary.contrastText + "!important",
    textDecoration: "none!important",
  },
  footerTitle: {
    color: theme.palette.primary.contrastText,
    fontSize: "18px",
    fontWeight: "600",
    paddingBottom: "20px",
  },
  text: {
    color: theme.palette.primary.contrastText,
    fontSize: "15px",
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },
}));

export default function SiteFooter(props) {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} className={classes.blur}>
        <Container className="py-5">
          <Grid container spacing={4}>
            <Grid item xs={12} md={5}>
              {/* <Typography className={classes.footerTitle}>Location</Typography> */}

              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.7704935545066!2d32.49742461475324!3d0.2805414997953199!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMMKwMTYnNTAuMCJOIDMywrAyOSc1OC42IkU!5e0!3m2!1sen!2sug!4v1615558154355!5m2!1sen!2sug"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography className={classes.footerTitle}>
                Programmes
              </Typography>
              {programmesList.map(({ path, hash, name }, index) => {
                return (
                  <Typography
                    smooth
                    className={classes.text}
                    component={HashLink}
                    to={hash}
                  >
                    {name}
                  </Typography>
                );
              })}
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className={classes.footerTitle}>
                Contact Info
              </Typography>

              <Typography className={classes.text}>
                <LocationCity />
                &nbsp;&nbsp;Kaaro Heights, 715 Buddo Vale-Nakasozi
              </Typography>
              <Typography className={classes.text}>
                <Email /> &nbsp;&nbsp; info@tripartiteproject.org
              </Typography>
              <Typography className={classes.text}>
                <Phone /> &nbsp;&nbsp;+256 200-910973
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid item xs={12} className={clsx(classes.copywrite)}>
        <Container className="d-flex flex-wrap align-items-center justify-content-between py-3">
          <Typography className={classes.footerText}>
            Tripartite Initiative for Resource Governance in Africa
          </Typography>
          <Typography
            className={classes.footerText}
            component="a"
            href="https://www.hapipie.com"
            style={{alignItems: "center"}}

          >
            <span style={{ fontSize: "19px" }}>&copy;&nbsp;</span>
            copyright
            Tripartite Initiative for Resource Governance in Africa 2020
          </Typography>
        </Container>
      </Grid>
    </Grid>
  );
}
