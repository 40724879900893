import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import {
  List,
  IconButton,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  Collapse,
} from "@material-ui/core";

import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  SortSharp as MenuIcon,
} from "@material-ui/icons";

import { NavLink } from "react-router-dom";
import { mappedImages, mappedLinks } from "../../maps";
import appLinks from "../../../site/routes/appLinks";

/**Classes  */
const useStyles = makeStyles((theme) => ({
  fullList: {
    width: 270,
  },

  activepath: {
    color: theme.palette.primary.path,
  },
}));

export default function SiteDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    collapseServices: false,
  });

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const collapseMenu = (e) => {
    e.stopPropagation();
    setState({ ...state, collapseServices: !state.collapseServices });
  };

  const fullList = (side) => (
    <div
      className={classes.fullList}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <List>
        <ListItem>
          <ListItemText
            align="center"
            primary={
              <mappedImages.Logo
                // src={images.app.logo}
                alt="Triga"
                style={{ height: "auto", width: "95%" }}
              />
            }
          />
        </ListItem>
        {appLinks.modules.map((item, index) => (
          <React.Fragment key={index}>
            {item.divider}
            <ListItem
              button
              activeClassName={classes.activeLink}
              component={NavLink}
              to={item.path}
              key={index}
            >
              <ListItemText primary={item.name} />
              <ListItemIcon>{item.icon}</ListItemIcon>
            </ListItem>
          </React.Fragment>
        ))}
        <Button
          color="primary"
          component="a"
          target="webmail"
          href="https://tripartiteproject.org/roundcube/">
          Staff Mail
        </Button>
      </List>
    </div>
  );

  return (
    <div>
      <IconButton color="inherit" onClick={toggleDrawer("left", true)}>
        <MenuIcon color="inherit" />
      </IconButton>
      <Drawer open={state.left} onClose={toggleDrawer("left", false)}>
        {fullList("left")}
      </Drawer>
    </div>
  );
}
